import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import UserTile from "./components/UserTile";

import { 
    Card, Table, TableBody, TableRow, TableCell, Button, TablePagination, Box 
} from "@mui/material";

import { getAxios, isOauth } from "../../utils";

import BarLoader from "react-spinners/BarLoader";
import { createSearchParams, useNavigate } from 'react-router-dom';
import UserFormModal from "./components/UserFormModal";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "components/SearchBar";
import TablePaginationActions from "components/TablePaginationActions";

const maxUsers = 10;

function Users() {
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [pageAssets, setPageAssets] = useState(0);
    const [erro, setErro] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(true);
    const [search, setSearch] = useState('');

    const navigate = useNavigate();
    
    const handleChangePage = (event, newpage) => setPageAssets(newpage);

    const handleGetUsers = async() => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/users";
        let response;
        const params = { 
            search,
            limitStart: pageAssets*maxUsers, 
            limit: maxUsers 
        }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setErro("Erro interno");
            return;
        }
        
        if(!response.data.result) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setUsers(response.data.result.users);
        setTotalUsers(response.data.result.total)
    }

    useEffect(async() => { 
        await handleGetUsers() 
        setUpdate(false)
    }, [update, pageAssets, search]);
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => { 
        setOpen(false);
        setUpdate(true);
    }

    const title = <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
    >
        <MDTypography variant="h6" color="white">
            Usuários
        </MDTypography>
    </MDBox>

    const handleSearch = async (value) => {
        setSearch(value);
        setPageAssets(0);
    } 

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <UserFormModal open={open} handleClose={handleClose} />
            {!isOauth() && 
                <Box
                    display='flex'
                    justifyContent='flex-end'
                >
                    <Button 
                        variant="contained" 
                        style={{color: 'white'}}
                        onClick={handleOpen}
                        startIcon={<AddIcon />}
                    >
                        Adicionar
                    </Button>
                </Box>
            }
            <MDBox mt={4}>
                {!!erro && <MDAlert color="error">{erro}</MDAlert>}
                <Card>
                    {title}
                    <MDBox pt={3}>
                        <SearchBar label="Pesquisar" onSearch={handleSearch} />
                        <Table>
                            <MDBox component="thead">
                                <TableRow>
                                    <TableCell><strong>Nome</strong></TableCell>
                                    <TableCell><strong>Email</strong></TableCell>
                                    <TableCell><strong>Data de Nascimento</strong></TableCell>
                                    <TableCell><strong>Status</strong></TableCell>
                                    <TableCell><strong>Tipo</strong></TableCell>
                                    <TableCell width="5"></TableCell>
                                </TableRow>
                            </MDBox>
                            <TableBody>{users.map((user, index) => <UserTile 
                                    key={`user_${index}`} 
                                    user={user} 
                                    updatedCallback={setUpdate}
                                />
                            )}</TableBody>
                        </Table>
                        {isLoading &&
                            <BarLoader
                                color="#ACC0F0" 
                                cssOverride={{ width: "100%" }}
                            />
                        }
                        <TablePagination 
                            rowsPerPageOptions={[maxUsers]}
                            component="div"
                            count={totalUsers}
                            rowsPerPage={maxUsers}
                            page={pageAssets}
                            onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}

export default Users;
