import React, { useEffect, useState } from 'react';

import { Box, CircularProgress, Collapse, Icon, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import S3Service from 'services/s3-service';
import ContentTile from './ContentTile';

function BucketTile({ bucket, storage, onSelect }) {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(true);

    const handleClick = () => setOpen(!open);

    const service = new S3Service(
        storage.accessKeyId,
        storage.secretAccessKey,
        storage.endpoint
    );

    console.log(storage);

    const handleGetBucket = async () => {
        if (service) {
            setLoading(true);
            setError(null);

            try {
                const response = await service.getAll(bucket);
                setItems(response);
            } catch (error) {
                setError('Erro Interno');
                setItems([])
            }

            setLoading(false);
        }
    }

    useEffect(() => { if (open) handleGetBucket() }, [open]);

    return (
        <Box ml={2}>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    <Icon>source</Icon>
                </ListItemIcon>
                <ListItemText primary={bucket} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open}>
                {loading
                    ? <CircularProgress />
                    : items.map((item, index) =>
                        <ContentTile
                            key={`content_${index}`}
                            storageId={storage.id}
                            object={item}
                            bucket={bucket}
                            service={service}
                            onSelect={onSelect}
                            space={2}
                        />
                    )}
            </Collapse>
        </Box>
    )
}

export default BucketTile;