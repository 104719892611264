import React, { useEffect, useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

import {
    Card, Button, Box, List, Icon, CircularProgress
} from "@mui/material";
import BucketTile from "./components/BucketTile";
import BucketFormModal from "./components/BucketFormModal";
import S3Service from "services/s3-service";
import { useNavigate, useParams } from "react-router-dom";
import { getAxios } from "utils";

function Bucket() {
    const [items, setItems] = useState([]);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(true);
    const [folder, setPrefix] = useState('');
    const [service, setService] = useState(null);
    const [storage, setStorage] = useState(null);

    const { id, bucket } = useParams();
    const navigate = useNavigate();

    const handleClick = (value) => setPrefix(value);

    const handleGetStorage = async () => {
        const axiosInstance = getAxios();

        const uri = `/proxy/admin/storage/${id}`;
        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data.result) {
            setError("Erro interno");
            return;
        }

        setError(undefined);
        setLoading(false);

        let storage = response.data.result.storage;
        let newService = new S3Service(
            storage.accessKeyId,
            storage.secretAccessKey,
            storage.endpoint
        );

        setStorage(storage);
        setService(newService);
    }

    const handleGetBucket = async () => {
        if (service) {

            setLoading(true);
            setError(null);

            try {
                const response = await service.getAll(bucket, folder);
                setItems(response);
            } catch (error) {
                setError('Erro Interno');
                setItems([])
            }

            setLoading(false);
        }
    }

    useEffect(() => {
        handleGetStorage();
    }, [id, bucket])

    useEffect(async () => {
        handleGetBucket();
        setUpdate(false);
    }, [update, folder, service])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setUpdate(true);
    }

    const handleGoBack = () => {
        if (folder.length == 0) navigate('/storage');
        setPrefix(
            path => {
                const segments = path.split('/');
                segments.pop();
                segments.pop();
                if (segments.length == 0) return ''
                return segments.join('/') + '/';
            }
        );
    }

    const title = <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
    >
        <MDTypography variant="h6" color="white">
            {storage && `${storage.name}/${bucket}/${folder}`}
        </MDTypography>
    </MDBox>;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <BucketFormModal
                open={open}
                handleClose={handleClose}
                service={service}
                bucket={bucket}
                folder={folder}
            />
            <Box
                display='flex'
                justifyContent='flex-end'
            >
                <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={handleOpen}
                >
                    Adicionar +
                </Button>
            </Box>
            <MDBox mt={4}>
                {!!error && <MDAlert color="error">{error}</MDAlert>}
                <Card>
                    {title}
                    <MDBox p={3}>
                        <Button
                            variant="contained"
                            style={{ color: 'white' }}
                            onClick={handleGoBack}
                            startIcon={<Icon>arrow_back</Icon>}
                        >
                            Voltar
                        </Button>
                        {loading
                            ? <CircularProgress />
                            : <List>
                                {items.map((item, index) => (<BucketTile
                                    key={`object_${index}`}
                                    service={service}
                                    bucket={bucket}
                                    folder={folder}
                                    object={item}
                                    updatedCallback={setUpdate}
                                    onClick={handleClick}
                                />))}
                            </List>
                        }
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}

export default Bucket;