import React, { useEffect, useState } from "react";

import {
    Dialog, DialogContent, DialogTitle, IconButton, Icon, Box, Table, TableBody, TableRow, TableCell, TablePagination
} from "@mui/material";
import AssetTile from "../AssetTile";
import { getAxios } from "utils";
import PackageFormModal from "../PackageFormModal";
import MDBox from "components/MDBox";
import SearchBar from "components/SearchBar";
import TVShowFilter from "./TVShowFilter";
import DefaultCheckField from "components/DefaultCheckField";

const maxAssets = 5;

function PackageModal({ open, handleClose, vodPackage }) {
    const [assets, setAssets] = useState([]);
    const [update, setUpdate] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [edit, setEdit] = useState(false);
    const [totalUsers, setTotalAssets] = useState(0);
    const [pageAssets, setPageAssets] = useState(0);
    const [search, setSearch] = useState('');
    const [tvShowId, setTvShowId] = useState(null);
    const [allSelected, setAllSelected] = useState(false);
    const { name } = vodPackage;

    const handleChangePage = (_, newPage) => setPageAssets(newPage);

    const handleTvShowChange = (value) => {
        setTvShowId(value)
        setPageAssets(0);
    };

    const handleSearch = (value) => {
        setSearch(value);
        setPageAssets(0);
    }

    const handleEdit = () => setEdit(true);
    const handleStopEdit = () => {
        setEdit(false);
        handleClose();
    }

    const handleEditAsset = async (assetId, value) => {
        const axiosInstance = getAxios();
        const uri = '/proxy/admin/assets';
        
        try {
            if (value) {
                const formData = new FormData();
                formData.append('packageId', vodPackage.id);
                formData.append('assetId', assetId);
                await axiosInstance.post(uri, formData);
            } 
            else {
                const params = {
                    packageId: vodPackage.id,
                    id: assetId
                }
                await axiosInstance.delete(uri, { params });
            }
            await handleGetAssets();
        } catch (err) {
            console.error(err);
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
            }
            setError('Erro interno');
        }
    }
    
    const handleEditAllAsset = async (value) => {
        const axiosInstance = getAxios();
        const uri = '/proxy/admin/vodPackages/all';
        
        try {
            if (allSelected) {
                const params = {
                    packageId: vodPackage.id,
                    categoryId: tvShowId,
                    search
                }
                await axiosInstance.delete(uri, { params });
            } 
            else {
                const formData = new FormData();
                formData.append('packageId', vodPackage.id);
                formData.append('categoryId', tvShowId);
                formData.append('search', search);
                await axiosInstance.post(uri, formData);
            }
            await handleGetAssets();
        } catch (err) {
            console.error(err);
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
            }
            setError('Erro interno');
        }
    }

    const handleGetAssets = async () => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/assets";
        let response;
        const params = {
            packageId: vodPackage.id,
            categoryId: tvShowId,
            search,
            limitStart: pageAssets * maxAssets,
            limit: maxAssets
        }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            
            if (err.response.data.error.code == 404) {
                setAssets([]);
                setTotalAssets(0);
                return;
            }

            setError("Erro interno");
            setLoading(false);
            return;
        }

        if (!response.data.result) {
            setError("Erro interno");
            setLoading(false);
            return;
        }

        setError(undefined);
        setLoading(false);
        setAssets(response.data.result.assets);
        setTotalAssets(response.data.result.total);
        setAllSelected(response.data.result.allSelected);
    }

    useEffect(() => {
        handleGetAssets();
        setUpdate(false);
    }, [vodPackage, update, pageAssets, search, tvShowId])
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='sm'
            scroll="paper"
        >
            <DialogTitle display='flex' justifyContent='space-between'>
                {name}
                <IconButton onClick={handleEdit}>
                    <Icon>edit</Icon>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <PackageFormModal
                    open={edit}
                    handleClose={handleStopEdit}
                    vodPackage={vodPackage}
                />
                <Box display="flex" justifyContent="space-between">
                    <Box mt={1}>
                        <SearchBar label="Pesquisar" onSearch={handleSearch} />
                    </Box>
                    <TVShowFilter value={tvShowId} onChange={handleTvShowChange} />
                </Box>
                <Table>
                    <MDBox component="thead">
                        <TableRow>
                            <TableCell width="5">
                                <DefaultCheckField
                                    checked={allSelected}
                                    onChange={handleEditAllAsset}
                                />
                            </TableCell>
                            <TableCell><strong>Nome</strong></TableCell>
                        </TableRow>
                    </MDBox>
                    <TableBody>
                        {assets.map((asset, index) =>
                            <AssetTile
                                key={`asset_${index}`}
                                asset={asset}
                                packageId={vodPackage.id}
                                onChange={handleEditAsset}
                            />
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[maxAssets]}
                    component="div"
                    count={totalUsers}
                    rowsPerPage={maxAssets}
                    page={pageAssets}
                    onPageChange={handleChangePage}
                />
            </DialogContent>
        </Dialog>
    )
}

export default PackageModal;
