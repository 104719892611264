import { useEffect, useState } from "react";
import { 
    Dialog, DialogTitle, DialogContent, Grid, Avatar 
} from "@mui/material";
import { imageUrl } from "utils";
import { getAxios } from "utils";
import { useNavigate } from "react-router-dom";

function AvatarModal({ open, handleClose, onSelect }) {
    const navigate = useNavigate();

    const [avatars, setAvatars] = useState([]);
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    const handleGetAvatars = async () => {
        const axiosInstance = getAxios();

        const uri = "/crm/admin/avatars";
        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setError("Erro interno");
            return;
        }
        
        if(!response.data.result) {
            setError("Erro interno");
            return;
        }

        setError(undefined);
        setLoading(false);
        setAvatars(response.data.result.avatars);
    }

    useEffect(handleGetAvatars, []);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Selecione um Avatar</DialogTitle>
            <DialogContent>
                {loading
                    ? <CircularProgress />
                    : <Grid container justifyContent='center'>
                        {avatars.map((avatar, index) => 
                            <Grid xs key={`avatar_${index}`} item>
                                <Avatar 
                                    onClick={() => onSelect(avatar.id)}
                                    src={imageUrl(
                                        window.stxConfig.imageTypes.avatar, 
                                        avatar.id, 
                                        50, 
                                        50
                                    )} 
                                />
                            </Grid>
                        )}
                    </Grid>
                }
            </DialogContent>
        </Dialog>
    )
}

export default AvatarModal;