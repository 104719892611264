import DefaultCheckField from "components/DefaultCheckField";
import DefaultDateField from "components/DefaultDateField";
import DefaultTextField from "components/DefaultTextField";
import MDBox from "components/MDBox";
import { useFormikContext } from "formik";

function MetaDataTab({ value, index }) {
    const {
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
    } = useFormikContext();

    return (
        <div hidden={value !== index}>
            {value === index && (
                <MDBox p={3}>
                    <DefaultTextField
                        required
                        label="Titulo"
                        name="title"
                        onChange={handleChange}
                        value={values.title}
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title && errors.title}
                    />
                    <DefaultTextField
                        label="Id externo"
                        name="externalId"
                        onChange={handleChange}
                        value={values.externalId}
                        error={touched.externalId && Boolean(errors.externalId)}
                        helperText={touched.externalId && errors.externalId}
                    />
                    <DefaultTextField
                        required
                        label="Gênero"
                        name="genre"
                        onChange={handleChange}
                        value={values.genre}
                        error={touched.genre && Boolean(errors.genre)}
                        helperText={touched.genre && errors.genre}
                    />
                    <DefaultTextField
                        label="Idade minima"
                        value={values.mLevel}
                        name='mLevel'
                        onChange={handleChange}
                        error={touched.mLevel && Boolean(errors.mLevel)}
                        helperText={touched.mLevel && errors.mLevel}
                        type="number"
                    />
                    <DefaultTextField
                        label="Duração (em minutos)"
                        value={values.DURATION}
                        name='DURATION'
                        onChange={handleChange}
                        error={touched.DURATION && Boolean(errors.DURATION)}
                        helperText={touched.DURATION && errors.DURATION}
                        type="number"
                    />
                    <DefaultTextField
                        label="Ano de lançamento"
                        value={values.RELEDATE}
                        name='RELEDATE'
                        onChange={handleChange}
                        error={touched.RELEDATE && Boolean(errors.RELEDATE)}
                        helperText={touched.RELEDATE && errors.RELEDATE}
                        type="number"
                    />
                    <DefaultTextField
                        label="CSASNAME"
                        value={values.CSASNAME}
                        name='CSASNAME'
                        onChange={handleChange}
                        error={touched.CSASNAME && Boolean(errors.CSASNAME)}
                        helperText={touched.CSASNAME && errors.CSASNAME}
                        type="number"
                    />
                    <DefaultTextField
                        label="Descrição"
                        value={values.SYNO600C}
                        name='SYNO600C'
                        onChange={handleChange}
                        error={touched.SYNO600C && Boolean(errors.SYNO600C)}
                        helperText={touched.SYNO600C && errors.SYNO600C}
                        multiline={true}
                    />
                    <DefaultCheckField
                        label="Conteúdo Adulto?"
                        name="adult"
                        checked={values.adult}
                        onChange={handleChange}
                    />
                    <DefaultCheckField
                        label="Permitir baixar?"
                        name="downloadable"
                        checked={values.downloadable}
                        onChange={handleChange}
                    />
                    <DefaultDateField
                        required
                        value={values.startDate}
                        label="Data de inicio"
                        onChange={val => setFieldValue('startDate', val)}
                        error={touched.startDate && Boolean(errors.startDate)}
                        helperText={touched.startDate && errors.startDate}
                    />
                    <DefaultDateField
                        required
                        value={values.endDate}
                        label="Data de fim"
                        onChange={val => setFieldValue('endDate', val)}
                        error={touched.endDate && Boolean(errors.endDate)}
                        helperText={touched.endDate && errors.endDate}
                    />
                </MDBox>
            )}
        </div>
    )
}

export default MetaDataTab;