import React, { useEffect, useState } from "react";

import { Dialog, DialogContent, DialogTitle, Icon, IconButton, Typography } from "@mui/material";
import TitleValue from "components/TitleValue";
import BranchFormModal from "../BranchFormModal";

function BranchModal({ open, handleClose, branch }) {
    const [edit, setEdit] = useState(false);

    const { name, oauth, payment, profiles } = branch
    const { endpoint, clientId, clientSecret } = oauth;

    const oauthSection = (
        <>
            <Typography>Oauth</Typography>
            <TitleValue
                title="url"
                value={endpoint}
            />
            <TitleValue
                title="clientId"
                value={clientId}
            />
            <TitleValue
                title="clientSecret"
                value={clientSecret}
            />
        </>
    );

    const paymentType = window.stxConfig.paymentTypes
        .find(type => type.value == payment.type)
    const paymentFields = payment.value != null ? Object.entries(payment.value) : [];

    const createField = (field, index) => {
        const key = `field_${index}`
        const title = paymentType?.fields.find(
            paymentField => paymentField.name == field[0]
        )?.label ?? '';

        return <TitleValue key={key} title={title} value={field[1]} />
    }

    const paymentSection = (
        <>
            <Typography>Pagamento</Typography>

            <TitleValue
                title="Tipo"
                value={paymentType?.label}
            />
            {paymentFields.map(createField)}
            <TitleValue
                title="Período de alerta de assinatura"
                value={payment.subscriptionWarning}
            />
        </>
    );

    const handleEdit = () => setEdit(true);
    const handleStopEdit = () => {
        setEdit(false);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='sm'
            scroll="paper"
        >
            <BranchFormModal handleClose={handleStopEdit} open={edit} branch={branch} />
            <DialogTitle display='flex' justifyContent='space-between'>
                {name}
                <IconButton onClick={handleEdit}>
                    <Icon>edit</Icon>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TitleValue
                    title="Máximo de perfis"
                    value={profiles}
                />
                {endpoint && oauthSection}
                {payment.type != 0 && paymentSection}
            </DialogContent>
        </Dialog>
    )
}

export default BranchModal;
