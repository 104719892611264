import { Box } from "@mui/material";
import ImageField from "components/ImageField";
import MDBox from "components/MDBox";
import { useFormikContext } from "formik";
import { imageUrl } from "utils";

function ImagesTab({ value, index }) {
    const {
        values,
        setFieldValue,
    } = useFormikContext();

    const onChangePoster = (image) => {
        setFieldValue('posterImage', image);
        setFieldValue('poster', image[0]?.file)
    }

    const onChangeCover = (image) => {
        setFieldValue('coverImage', image);
        setFieldValue('cover', image[0]?.file)
    }

    return (
        <div hidden={value !== index}>
            {value === index && (
                <MDBox p={3}>
                    <ImageField
                        label="Poster"
                        value={values.posterImage}
                        onChange={onChangePoster}
                        initialValue={values.id && values.poster && imageUrl(
                            window.stxConfig.imageTypes.movieEpisodePoster,
                            values.id, 152, 230
                        )}
                        width={152}
                        height={230}
                    />
                    <Box mt={2}>
                        <ImageField
                            label="Cover"
                            value={values.coverImage}
                            onChange={onChangeCover}
                            initialValue={values.id && values.cover && imageUrl(
                                window.stxConfig.imageTypes.movieEpisodeCover,
                                values.id, 380, 192
                            )}
                            width={380}
                            height={192}
                        />
                    </Box>
                </MDBox>
            )}
        </div>
    )
}

export default ImagesTab;