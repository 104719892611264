import React, { useEffect, useState } from "react";

import { Relatorios } from '../../../utils';
import { TableCell }from "@mui/material";

function RelatorioUsuario({ relatorio }) {
    const [tempoLogado, setTempoLogado] = useState(0);
    const [emailUsuario, setEmailUsuario] = useState("");
    const [localidade, setLocalidade] = useState({});
    const [ip, setIp] = useState("");
    const [operadoraUser, setOperadora] = useState(0);
    const [isLogedUser, setIsLoged] = useState(0);
    const [dataLogado, setDataLogado] = useState("");

    const handleTratamentoDados = () => {
        const { email, data, tempo, isLoged, operadora } = relatorio;
        
        setTempoLogado(tempo);
        setIsLoged(isLoged);
        setDataLogado(data);
        setEmailUsuario(email);
        setOperadora(operadora);
    }

    useEffect(() => {
        return handleTratamentoDados();
    }, [])

    return (
        <>
            <TableCell scope="row">{emailUsuario}</TableCell> 
            <TableCell scope="row">{dataLogado}</TableCell>
            <TableCell scope="row">{tempoLogado}</TableCell>
            <TableCell scope="row">{Relatorios.acao[isLogedUser]}</TableCell>
            <TableCell scope="row">{Relatorios.operadoras[operadoraUser]}</TableCell>
        </>
    )
}

export default RelatorioUsuario;
