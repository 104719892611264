import { Box, Button, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import { Fragment } from "react";
import ReactImageUploading from "react-images-uploading";

function ImageField({
    initialValue = null, value, onChange, label = null, width = 350, height = 200
}) {
    return (
        <ReactImageUploading
            value={value}
            onChange={onChange}
        >
            {({
                imageList,
                onImageUpload,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
            }) => (
                <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                >
                    {label && <Typography variant="h4">{label}</Typography>}
                    {value?.length == 0 && initialValue &&
                        <img 
                            src={initialValue} 
                            alt="" 
                            width={width} 
                            height={height}
                        />
                    }
                    {
                        imageList.length > 0
                            ? imageList.map((image, index) => (
                                <Fragment key={`image_${index}`}>
                                    <img 
                                        src={image.dataURL} 
                                        alt="" 
                                        width={width} 
                                        height={height}
                                    />
                                    <div>
                                        <MDButton
                                            color="warning"
                                            onClick={() => onImageUpdate(index)}
                                        >
                                            Atualizar
                                        </MDButton>

                                        <MDButton
                                            color="error"
                                            onClick={() => onImageRemove(index)}
                                        >
                                            Remover
                                        </MDButton>
                                    </div>
                                </Fragment>)
                            )
                            : <Button
                                variant="contained"
                                style={isDragging ? null : { color: 'white' }}
                                color={isDragging ? 'error' : 'primary'}
                                onClick={onImageUpload}
                                {...dragProps}
                            >
                                Inserir nova imagem
                            </Button>
                    }
                </Box>
            )}
        </ReactImageUploading>
    )

}

export default ImageField;