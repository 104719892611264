import { Formik } from 'formik';
import { useState } from "react";
import * as yup from 'yup';

import { Alert, Box, Button, Icon, IconButton, Tab, Tabs } from "@mui/material";

import MDBox from 'components/MDBox';
import { useNavigate } from "react-router-dom";
import { getAxios } from "utils";
import DefaultAutoCompleteField from 'components/DefaultAutoCompleteField';
import MetaDataTab from '../AssetFormModal/MetaDataTab';
import ImagesTab from '../AssetFormModal/ImagesTab';
import MediaTab from '../AssetFormModal/MediaTab';
import MaterialTab from '../AssetFormModal/MaterialTab';
import moment from 'moment';

function AssetTab({ value, index, onClose, catalogId = '', categoryId = '' }) {
    const navigate = useNavigate();

    const [assets, setAssets] = useState([]);
    const [assetId, setAssetId] = useState(0);
    const [add, setAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);

    const handleChangePage = (_, newPage) => setPage(newPage);

    const handleGetAssets = async () => {
        setLoading(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/assets";
        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            if (err.response.data.error.code == 404) {
                setError("Não há mais pacotes disponíveis")
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        setError(undefined);

        let assetAux = response.data.result.assets.map(asset => ({
            label: asset.name,
            id: asset.id
        }));
        setAssets(assetAux);
        setLoading(false);
    }

    const initialValues = {
        catalog: catalogId, categoryId: categoryId, externalId: '', adult: false,
        downloadable: false, language: '', mLevel: 0, title: '', endDate: '',
        startDate: '', language: 'por', genre: '', DURATION: 0, RELEDATE: 0, 
        CSASNAME: 0, coverImage: '', cover: '', posterImage: '', poster: '', 
        media: '', materials: []
    };

    const saveChanges = async (values = null) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        try {
            if (add) {
                const uri = '/proxy/admin/assets';
                for (let [key, value] of Object.entries(values)) {
                    if (typeof value === 'object'
                        && !(value instanceof File)
                        && !(value instanceof Blob)
                    ) value = JSON.stringify(value); 
                    formData.append(key, value);
                }
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/assets/connect'
                formData.append('categoryId', categoryId)
                formData.append('id', assetId)
                await axiosInstance.post(uri, formData);
            }
            return true;
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return false;
            }
            setError('Erro interno');
            return false;
        }
    }

    const validationSchema = yup.object({
        title: yup.string().required(),
        genre: yup.string().required(),
        mLevel: yup
            .number()
            .required()
            .max(21, 'Idade máxima não pode ser maior que 21')
            .min(0, 'Idade máxima não pode ser menor que 0 (Livre)'),
        materials: yup.array().of(
            yup.object().shape({
                name: yup.string().required(),
                path: yup.string().required()
            })
        ),
        startDate: yup.date().required(),
        endDate: yup
            .string()
            .test({
                name: 'valid date',
                test(value, ctx) {
                    const endDate = moment(value);
                    const startDate = moment(yup.ref('startDate'));
                    return endDate.isAfter(startDate)
                        ? true
                        : ctx.createError({ message: 'Data inválida' })
                }
            }).required(),
    });

    let form = <Box mt={2}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
                value={page}
                onChange={handleChangePage}
            >
                <Tab label="Metadata" />
                <Tab label="Imagens" />
                <Tab label="Media" />
                <Tab label="Material" />
            </Tabs>
        </Box>
        <MetaDataTab index={0} value={page} />
        <ImagesTab index={1} value={page} />
        <MediaTab index={2} value={page} />
        <MaterialTab index={3} value={page} />
    </Box>

    const handleNewAsset = () => setAdd(true);

    const handleConnect = () => {
        if (saveChanges()) onClose();
    }

    return (
        <div hidden={value !== index}>
            {value === index && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { resetForm }) => {
                        if (await saveChanges(values)) {
                            onClose();
                            resetForm();
                        }
                    }}
                >
                    {({ handleSubmit }) =>
                        <MDBox p={3}>
                            {error && error != '' &&
                                <Alert
                                    severity="error"
                                    onClose={() => setError(undefined)}
                                >
                                    {error}
                                </Alert>
                            }
                            <Box display='flex' sx={{ width: 1 }} >
                                <DefaultAutoCompleteField
                                    fullWidth
                                    disabled={add}
                                    label="Asset"
                                    loading={loading}
                                    value={value}
                                    onChange={(value) => setAssetId(value?.id)}
                                    options={assets}
                                    onOpen={handleGetAssets}
                                />

                                <IconButton disabled={add} onClick={handleNewAsset}>
                                    <Icon>add</Icon>
                                </IconButton>
                            </Box>
                            {add && form}
                            <Button color="warning" onClick={onClose}>Cancelar</Button>
                            <Button onClick={add ? handleSubmit : handleConnect}>Salvar</Button>
                        </MDBox>
                    }
                </Formik>
            )}
        </div>
    )
}

export default AssetTab;