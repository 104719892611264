import React, { useState } from "react";
import moment from "moment";

import { 
    Dialog, DialogContent, DialogTitle, Tabs, Tab, Box  
} from "@mui/material";
import CategoryTab from "./CategoryTab";
import AssetTab from "./AssetTab";

moment.updateLocale('en', { invalidDate : 'Não definido' });

function ContentFormModal({ open, onClose, catalogId = '', parentId = '' }) {
    const [page, setPage] = useState(0);

    const handleChangePage = (_, newPage) => setPage(newPage);

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle>Novo Conteúdo</DialogTitle>
            <DialogContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={page} onChange={handleChangePage}>
                        <Tab label="Categoria" />
                        <Tab label="Asset" />
                    </Tabs>
                </Box>
                <CategoryTab 
                    onClose={onClose} 
                    catalogId={catalogId} 
                    parentId={parentId} 
                    value={page} 
                    index={0}
                />
                <AssetTab 
                    onClose={onClose} 
                    catalogId={catalogId} 
                    categoryId={parentId} 
                    value={page} 
                    index={1}
                />
            </DialogContent>
        </Dialog>
    )
}

export default ContentFormModal;