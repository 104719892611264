import React, { useState } from "react";

import { Icon, IconButton, TableCell, TableRow } from "@mui/material";
import UserClassModal from "../UserClassModal";
import moment from "moment";
import { getAxios } from "utils";
import { useNavigate } from "react-router-dom";

function UserClassTile({ userClass }) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const { id, userName, teacherName, date, status, liveId, running } = userClass;
    const formattedDate = moment(date).format('DD/MM/YYYY HH:mm:ss')
    const statusName = running 
        ? "Em Andamento" 
        : window.stxConfig.userClassStatus[status] ?? 'Não definido';

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleEnter = async (event) => {
        event.stopPropagation();

        const axiosInstance = getAxios();
        const uri = '/proxy/admin/userClassHistory/enter';
        const formData = new FormData();
        formData.append("id", liveId);

        try {
            const response = await axiosInstance.post(uri, formData);
            console.log(response);
            const url = response.data.result.url;
            window.open(url, "_blank");
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    } 
    
    return (
        <>
            <UserClassModal open={open} handleClose={handleClose} userClass={userClass} />
            <TableRow style={{ cursor: 'pointer' }} onClick={handleOpen}>
                <TableCell scope="row">{id}</TableCell>
                <TableCell scope="row">{userName}</TableCell>
                <TableCell scope="row">{teacherName}</TableCell>
                <TableCell scope="row">{formattedDate}</TableCell>
                <TableCell scope="row">{statusName}</TableCell>
                <TableCell scope="row">
                    {running &&
                        <IconButton onClick={handleEnter}>
                            <Icon>login</Icon>
                        </IconButton>
                    }
                </TableCell>
            </TableRow>
        </>
    )
}

export default UserClassTile;
