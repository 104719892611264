import { useFormik } from 'formik';
import { useState } from "react";
import * as yup from 'yup';

import { Alert, Box, Button } from "@mui/material";

import DefaultCheckField from "components/DefaultCheckField";
import DefaultTextField from "components/DefaultTextField";
import { useNavigate } from "react-router-dom";
import { getAxios } from "utils";
import MDBox from 'components/MDBox';
import ImageField from 'components/ImageField';

function CategoryTab({ value, index, onClose, catalogId = '', parentId = '' }) {
    const navigate = useNavigate();

    const [error, setError] = useState(undefined);

    const initialValues = {
        catalog: catalogId, externalId: '', parent: parentId, adult: false,
        dynamic: false, fictif: false, idView: '', main: false, mLevel: 0,
        name: '', order: 0, season: false, serie: false, synopsis: '',
        coverImage: '', cover: '', posterImage: '', poster: ''
    };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        try {
            const uri = '/proxy/admin/categories';
            await axiosInstance.post(uri, formData);
            return true;
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return false;
            }
            setError('Erro interno');
            return false;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
        mLevel: yup
            .number()
            .required()
            .max(21, 'Idade máxima não pode ser maior que 21')
            .min(0, 'Idade máxima não pode ser menor que 0 (Livre)'),
        idView: yup.number().required()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue
    } = useFormik({
        initialValues,
        validationSchema,
        async onSubmit(values, { resetForm }) {
            if (await saveChanges(values)) {
                onClose();
                resetForm();
            }
        }
    });
    
    const onChangePoster = (image) => {
        setFieldValue('posterImage', image);
        setFieldValue('poster', image[0]?.file)
    }

    const onChangeCover = (image) => {
        setFieldValue('coverImage', image);
        setFieldValue('cover', image[0]?.file)
    }

    return (
        <div hidden={value !== index}>
            {value === index && (
                <MDBox p={3}>
                    {error && error != '' &&
                        <Alert
                            severity="error"
                            onClose={() => setError(undefined)}
                        >
                            {error}
                        </Alert>
                    }
                    <ImageField 
                        label="Poster"
                        value={values.posterImage}
                        onChange={onChangePoster} 
                    />
                    <Box mt={2}>
                        <ImageField 
                            label="Cover"
                            value={values.coverImage}
                            onChange={onChangeCover} 
                        />
                    </Box>
                    <DefaultTextField
                        required
                        label="Nome"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                    />
                    <DefaultTextField
                        label="Id externo"
                        name="externalId"
                        onChange={handleChange}
                        value={values.externalId}
                        error={touched.externalId && Boolean(errors.externalId)}
                        helperText={touched.externalId && errors.externalId}
                    />
                    <DefaultTextField
                        required
                        label="Idade máxima"
                        value={values.mLevel}
                        name='mLevel'
                        onChange={handleChange}
                        error={touched.mLevel && Boolean(errors.mLevel)}
                        helperText={touched.mLevel && errors.mLevel}
                        type="number"
                    />
                    <DefaultTextField
                        required
                        label="idView"
                        value={values.idView}
                        name='idView'
                        onChange={handleChange}
                        error={touched.idView && Boolean(errors.idView)}
                        helperText={touched.idView && errors.idView}
                        type="number"
                    />
                    <DefaultCheckField
                        label="Conteúdo Adulto?"
                        name="adult"
                        checked={values.adult}
                        onChange={handleChange}
                    />
                    <DefaultCheckField
                        label="Dynamic?"
                        name="dynamic"
                        checked={values.dynamic}
                        onChange={handleChange}
                    />
                    <DefaultCheckField
                        label="Fictif?"
                        name="fictif"
                        checked={values.fictif}
                        onChange={handleChange}
                    />
                    <DefaultCheckField
                        label="Main?"
                        name="main"
                        checked={values.main}
                        onChange={handleChange}
                    />
                    <DefaultCheckField
                        label="Main?"
                        name="main"
                        checked={values.main}
                        onChange={handleChange}
                    />
                    <DefaultCheckField
                        label="É série?"
                        name="serie"
                        checked={values.serie}
                        onChange={handleChange}
                    />
                    <DefaultCheckField
                        label="É uma temporada?"
                        name="season"
                        checked={values.season}
                        onChange={handleChange}
                    />
                    <Button color="warning" onClick={onClose}>Cancelar</Button>
                    <Button onClick={handleSubmit}>Salvar</Button>
                </MDBox>
            )}
        </div>
    )
}

export default CategoryTab;