import React, { useEffect, useState } from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Alert
} from "@mui/material";

import { getAxios } from "utils";
import { useNavigate } from "react-router-dom";
import DefaultAutoCompleteField from "components/DefaultAutoCompleteField";

function ChannelFormModal({ open, handleClose, planId }) {
    const navigate = useNavigate();

    const [channels, setChannels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const initialState = { channelId: '' };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }
        formData.append('optionId', planId);

        try {
            const uri = '/proxy/admin/liveChannels';
            await axiosInstance.post(uri, formData);
            return true;
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return false;
            }
            setError('Erro interno');
            return false;
        }
    }

    const validationSchema = yup.object({
        channelId: yup.number().required(),
    });

    const {
        values,
        touched,
        errors,
        setFieldValue,
        handleSubmit,
    } = useFormik({
        initialValues: initialState,
        validationSchema,
        async onSubmit(values, { resetForm }) {
            if (await saveChanges(values)) {
                handleClose();
                resetForm();
            }
        }
    });

    const handleGetChannels = async () => {
        setLoading(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/liveChannels";
        const params = { excludeOptionId: planId }
        let response;

        try {
            response = await axiosInstance.get(uri, { params });
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            if (err.response.data.error.code == 404) {
                setError("Não há mais canais disponíveis")
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        setError(undefined);

        let channelsAux = response.data.result.channels.map(channel => ({
            label: channel.name,
            id: channel.id
        }));
        setChannels(channelsAux);
        setLoading(false);
    }

    useEffect(() => {
        if (open) handleGetChannels();
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Novo Canal</DialogTitle>
            <DialogContent>
                {error && error != '' &&
                    <Alert
                        severity="error"
                        onClose={() => setError(undefined)}
                    >
                        {error}
                    </Alert>
                }
                {!loading && 
                    <DefaultAutoCompleteField
                        label="Canal"
                        value={values.channelId}
                        onChange={newValue => {
                            const value = newValue ? newValue.id : '';
                            setFieldValue('channelId', value)
                        }}
                        options={channels}
                        error={touched.channelId && Boolean(errors.channelId)}
                        helperText={touched.channelId && errors.channelId}
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChannelFormModal;
