import React, { useState } from "react";

import {
    Dialog, DialogContent, DialogTitle, Icon, Box, Button
} from "@mui/material";
import LiveFormModal from "../LiveFormModal";
import TitleValue from "components/TitleValue";
import { sqlToDateTime } from "utils";
import { imageUrl } from "utils";

function LiveModal({ open, handleClose, live }) {
    const { 
        id, title, date, active, description, teacherName
    } = live
    const thumbUrl = imageUrl(
        window.stxConfig.imageTypes.liveCover,
        id, 620, 300
    );
    const activeName = active ? 'Sim' : 'Não';

    const [edit, setEdit] = useState(false);

    const handleEdit = () => setEdit(true);
    const handleStopEdit = () => {
        setEdit(false);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <LiveFormModal
                    open={edit}
                    handleClose={handleStopEdit}
                    live={live}
                />

                <img src={thumbUrl} alt="" width="540" height="300" />

                <TitleValue title='Descrição' value={description} />
                <TitleValue title='Data' value={sqlToDateTime(date)} />
                <TitleValue title='Professor' value={teacherName} />
                <TitleValue title='Ativa' value={activeName} />

                <Box display='flex' justifyContent='flex-end'>
                    <Button
                        variant="contained"
                        style={{ color: 'white' }}
                        onClick={handleEdit}
                        startIcon={<Icon>edit</Icon>}
                    >
                        Editar
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default LiveModal;
