import { Box, Button, Grid, Typography } from "@mui/material"
import MDBox from "components/MDBox";
import PlanFormModal from "../PlanFormModal";
import { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import TitleValue from "components/TitleValue";
import { timestampToDateTime } from "utils";

function PlanDataTabPanel({ plan, value, index, onClose }) {
    const [open, setOpen] = useState(false);
    const { priceDetail, validFrom, validTo, type } = plan;
    const { recurrenceUnit } = priceDetail;
    
    const typeName = window.stxConfig.planTypes.find(({value}) => value == type).label;
    const validity = timestampToDateTime(validFrom) + ' - ' + timestampToDateTime(validTo);
    const recurrenceName = window
        .stxConfig
        .recurrenceTypes
        .find(({value}) => value == recurrenceUnit)
        .label;

    const DataSection = <Grid container columnSpacing={2}>
        <Grid item xs={12}>
            <TitleValue
                title="Nome"
                value={plan.name}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue
                title="Branch"
                value={plan.branchName}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue
                title="É único por pessoa?"
                value={plan.isPerPersonUnique ? 'Sim' : 'Não'}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue
                title="Visível?"
                value={plan.visible ? 'Sim' : 'Não'}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue
                title="Tipo"
                value={typeName}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue
                title="Máximo de Dispositivos conectados"
                value={plan.maxDevices}
            />
        </Grid>
    </Grid>

    const PriceSection = <Grid container columnSpacing={2}>
        <Grid item xs={12}>
            <TitleValue
                title="Valor"
                value={plan.priceDetail.setup}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue
                title="Moeda"
                value={plan.priceDetail.currency}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue
                title="Recorrência"
                value={recurrenceName}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue
                title="Período"
                value={plan.priceDetail.recurring}
            />
        </Grid>
        <Grid item xs={12}>
            <TitleValue
                title="Período Gratuito (em dias)"
                value={plan.freePeriod}
            />
        </Grid>
    </Grid>;

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <MDBox sx={{ p: 3 }}>
                    <PlanFormModal
                        open={open}
                        handleClose={handleClose}
                        plan={plan}
                    />
                    <MDBox mb={1}>
                        {DataSection}
                    </MDBox>

                    <MDBox mb={1}>
                        <Typography variant="h4" component='h2'>
                            Descrição
                        </Typography>
                        {plan.synopsis}
                    </MDBox>

                    <MDBox mb={1}>
                        <Typography variant="h4" component='h2'>
                            Preço
                        </Typography>
                        {PriceSection}
                    </MDBox>

                    <MDBox mb={1}>
                        <Typography variant="h4" component='h2'>
                            Validade
                        </Typography>
                        {validity}
                    </MDBox>

                    <Box display='flex' justifyContent='flex-end'>
                        <Button
                            variant="contained"
                            style={{ color: 'white' }}
                            onClick={handleOpen}
                            startIcon={<EditIcon />}
                        >
                            Editar
                        </Button>
                    </Box>

                </MDBox>
            )}
        </div>
    )

}

export default PlanDataTabPanel;
