import React, { useEffect, useState } from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Alert
} from "@mui/material";

import { getAxios } from "utils";
import { useNavigate } from "react-router-dom";
import DefaultTextField from "components/DefaultTextField";

function PackageFormModal({ open, handleClose, vodPackage }) {
    const navigate = useNavigate();

    const [error, setError] = useState(undefined);
    const [title, setTitle] = useState(vodPackage != null
        ? vodPackage.name 
        : 'Novo Plano'
    );

    const initialState = { name: '' };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        try {
            if (vodPackage != null) {
                const uri = '/proxy/admin/vodPackages/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/vodPackages';
                await axiosInstance.post(uri, formData);
            }
            return true;
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return false;
            }
            setError('Erro interno');
            return false;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: vodPackage ?? initialState,
        validationSchema,
        async onSubmit(values, { resetForm }) {
            if (await saveChanges(values)) {
                handleClose();
                resetForm();
            }
        }
    });
    
    useEffect(() => {
        if (vodPackage != null) {
            setTitle(values.name);
        }
    }, [vodPackage, values.name])
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {error && error != '' &&
                    <Alert
                        severity="error"
                        onClose={() => setError(undefined)}
                    >
                        {error}
                    </Alert>
                }
                <DefaultTextField 
                    label="Nome"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                />
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PackageFormModal;