import { useEffect, useState } from "react";
import { SimpleTreeItemWrapper } from "dnd-kit-sortable-tree";
import { CircularProgress, Icon, IconButton, ListItem, ListItemText } from "@mui/material";
import CategoryFormModal from "../CategoryFormModal";
import AssetFormModal from "../AssetFormModal";
import WarningModal from "components/WarningModal";
import { getAxios } from "utils";
import ContentFormModal from "../ContentFormModal";

function ContentTile({
    props, innerRef, catalogId, onUpdate, onUpdateChildren, editing, onEditing, onStopEditing
}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { id: hierarchyId, name, type, parentId, children } = props.item;

    const id = type == 'category'
        ? props.item.category?.id
        : props.item.asset?.id;
    const typeName = type == 'category' ? 'categoria' : 'asset';
    const open = children?.length > 0;

    const handleAdd = (event) => {
        event.stopPropagation();
        onEditing({ type: 'add', id: hierarchyId });
    }
    const handleStopAdd = () => {
        onStopEditing();
        onUpdate();
    }

    const handleEdit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onEditing({ type: 'edit', id: hierarchyId });
    }
    const handleStopEdit = () => {
        onStopEditing();
        onUpdate();
    }

    const handleDelete = async (e) => {
        e.preventDefault();

        const axiosInstance = getAxios();

        try {
            if (type == 'category') {
                const uri = '/proxy/admin/categories';
                const params = { id }
                await axiosInstance.delete(uri, { params });
            } else {
                const uri = '/proxy/admin/assets';
                const params = { id, categoryId: parentId.replace(/\D/g, "") }
                await axiosInstance.delete(uri, { params });
            }
            handleCloseDeleteWarning();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
            }
        }
    }

    const handleOpenDeleteWarning = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onEditing({type: 'delete', id: hierarchyId})
    }
    const handleCloseDeleteWarning = () => {
        onUpdate();
        onStopEditing();
    }

    const handleOpen = async () => {
        setLoading(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/listContent";
        let response;
        const params = { id }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        let contents = response.data.result.contents;
        onUpdateChildren(`${type}_${id}`, contents);
        setLoading(false);
    }

    const handleClose = () => {
        onUpdateChildren(`${type}_${id}`, []);
    }

    return (
        <SimpleTreeItemWrapper
            {...props}
            ref={innerRef}
            onCollapse={null}
        >
            <ContentFormModal
                open={editing?.type === 'add' && editing?.id === hierarchyId}
                onClose={handleStopAdd}
                catalogId={catalogId}
                parentId={id}
            />
            {type == 'asset'
                ? <AssetFormModal
                    open={editing?.type === 'edit' && editing?.id === hierarchyId}
                    onClose={handleStopEdit}
                    asset={props.item.asset}
                    catalogId={catalogId}
                />
                : <CategoryFormModal
                    open={editing?.type === 'edit' && editing?.id === hierarchyId}
                    handleClose={handleStopEdit}
                    category={props.item.category}
                    catalogId={catalogId}
                />
            }
            <WarningModal
                open={editing?.type === 'delete' && editing?.id === hierarchyId}
                handleClose={handleCloseDeleteWarning}
                handleAccept={handleDelete}
                handleDismiss={handleCloseDeleteWarning}
                title='Deletar Usuário'
                message={`Tem certeza que deseja deletar ${typeName} ${name}?`}
            />
            <ListItem component='div'>
                <IconButton onClick={!loading ? open ? handleClose : handleOpen : null}>
                    {type == 'category' && (
                        !loading
                            ? <Icon>{open
                                ? 'keyboard_arrow_up'
                                : 'keyboard_arrow_down'
                            }</Icon>
                            : <CircularProgress />
                    )}
                </IconButton>
                <ListItemText primary={name} />
                {type == 'category' &&
                    <IconButton onClick={handleAdd}>
                        <Icon>add</Icon>
                    </IconButton>
                }
                <IconButton onClick={handleEdit}>
                    <Icon>edit</Icon>
                </IconButton>
                <IconButton onClick={handleOpenDeleteWarning}>
                    <Icon>delete</Icon>
                </IconButton>
            </ListItem>
        </SimpleTreeItemWrapper>
    )
}

export default ContentTile;