import React, { useEffect, useState } from "react";
import * as yup from 'yup';
import { cpf } from "cpf-cnpj-validator";

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, Typography,
} from "@mui/material";

import { getAxios } from "utils";
import DefaultTextField from "components/DefaultTextField";
import DefaultDateField from "components/DefaultDateField";
import DefaultCheckField from "components/DefaultCheckField";
import DefaultSelectField from "components/DefaultSelectField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

function UserFormModal({ open, handleClose, user = null }) {
    const navigate = useNavigate();

    const [title, setTitle] = useState(user != null
        ? user.name + ' ' + (user.lastName ?? '')
        : 'Novo Usuário'
    );

    const initialValues = {
        status: user?.status ?? true,
        name: user?.name ?? '',
        lastName: user?.lastName ?? '',
        email: user?.email ?? '',
        address: user?.address ?? '',
        addressNumber: user?.addressNumber ?? '',
        address2: user?.address2 ?? '',
        city: user?.city ?? '',
        state: user?.state ?? '',
        country: user?.country ?? '',
        cpf: user?.cpf ?? '',
        dob: user?.dob ?? '',
        cep: user?.cep ?? '',
        phone: user?.phone ?? '',
        type: user?.type ?? ''
    };

    const saveChanges = async (values) => {
        const formData = new FormData();

        const axiosInstance = getAxios();

        for (let [key, value] of Object.entries(values)) {
            if (key == 'cpf') {
                value = value.replace(/\D/g, '');
            }
            formData.append(key, value);
        }

        try {
            if (user != null) {
                const uri = '/proxy/admin/users/edit';
                formData.append('userId', user.id);
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/users';
                await axiosInstance.post(uri, formData);
            }
            handleClose();
            resetForm();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
        email: yup
            .string()
            .email('Insira um email valido')
            .required(),
        cpf: yup
            .string()
            .test({
                name: "CPF inválido",
                test(value, ctx) {
                    return cpf.isValid(value)
                        ? true
                        : ctx.createError({ message: "CPF inválido" })
                }
            })
            .required(),
        type: yup.number().required(),
        phone: yup
            .string()
            .matches(
                /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/,
                'Telefone invalido'
            ),
        cep: yup
            .string()
            .matches(
                /^[0-9]{5}\-[0-9]{3}$/,
                'CEP invalido'
            ),
        password: yup.string(),
        confirmPassword: yup
            .string()
            .when('password', {
                is: password => password,
                then: yup
                    .string()
                    .oneOf(
                        [yup.ref("password"), ''],
                        "Senhas devem ser iguais"
                    )
                    .required()
            })
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
        resetForm
    } = useFormik({
        initialValues,
        onSubmit: saveChanges,
        validationSchema,
    });

    useEffect(() => {
        if (user != null) {
            setTitle(values.name + ' ' + (values.lastName ?? ''));
        }
    }, [user, values.name, values.lastName])

    const PersonalSection = <Grid container columnSpacing={2}>
        <Grid item xs={6}>
            <DefaultTextField
                label="Nome"
                value={values.name}
                name='name'
                onChange={handleChange}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                required
            />
        </Grid>
        <Grid item xs={6}>
            <DefaultTextField
                label="Sobrenome"
                value={values.lastName}
                name='lastName'
                onChange={handleChange}
            />
        </Grid>
        <Grid item xs={8}>
            <DefaultTextField
                label="Email"
                value={values.email}
                name='email'
                onChange={handleChange}
                type="email"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                required
            />
        </Grid>
        <Grid item xs={4}>
            <DefaultTextField
                label="Telefone"
                value={values.phone}
                name='phone'
                onChange={handleChange}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
                type="phone"
            />
        </Grid>
        <Grid item xs={8}>
            <DefaultTextField
                label="CPF"
                value={values.cpf}
                name='cpf'
                onChange={handleChange}
                error={touched.cpf && Boolean(errors.cpf)}
                helperText={touched.cpf && errors.cpf}
                mask="999.999.999-99"
                required
            />
        </Grid>
        <Grid item xs={4}>
            <DefaultDateField
                value={values.dob}
                label="Data de Nascimento"
                onChange={val => setFieldValue('dob', val)}
            />
        </Grid>
    </Grid>

    const AddressSection = <Grid container columnSpacing={1}>
        <Grid item xs={10}>
            <DefaultTextField
                label="Endereço"
                value={values.address}
                name='address'
                onChange={handleChange}
            />
        </Grid>
        <Grid item xs={2}>
            <DefaultTextField
                label="Número"
                value={values.addressNumber}
                name='addressNumber'
                onChange={handleChange}
                type="number"
            />
        </Grid>
        <Grid item xs={9}>
            <DefaultTextField
                label="Complemento"
                value={values.address2}
                name='address2'
                onChange={handleChange}
            />
        </Grid>
        <Grid item xs={3}>
            <DefaultTextField
                label="CEP"
                value={values.cep}
                name='cep'
                onChange={handleChange}
                mask="99999-999"
                error={touched.cep && Boolean(errors.cep)}
                helperText={touched.cep && errors.cep}
            />
        </Grid>
        <Grid item xs>
            <DefaultTextField
                label="Cidade"
                value={values.city}
                name='city'
                onChange={handleChange}
            />
        </Grid>
        <Grid item xs>
            <DefaultTextField
                label="Estado"
                value={values.state}
                name='state'
                onChange={handleChange}
            />
        </Grid>
        <Grid item xs>
            <DefaultTextField
                label="País"
                value={values.country}
                name='country'
                onChange={handleChange}
            />
        </Grid>
    </Grid>;

    const PasswordSection = <>
        <Typography variant="h3" component="h3">
            Definir Senha
        </Typography>
        <Grid container columnSpacing={1}>
            <Grid item xs>
                <DefaultTextField
                    label="Senha"
                    value={values.password}
                    name='password'
                    onChange={handleChange}
                    type="password"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                />
            </Grid>
            <Grid item xs>
                <DefaultTextField
                    label="Confirme a senha"
                    value={values.confirmPassword}
                    name='confirmPassword'
                    onChange={handleChange}
                    type="password"
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                />
            </Grid>
        </Grid>
    </>

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {PersonalSection}

                {AddressSection}

                <DefaultCheckField
                    label="Usuário Ativo?"
                    name="status"
                    checked={values.status}
                    onChange={handleChange}
                />

                <DefaultSelectField
                    label="Tipo"
                    required
                    name="type"
                    items={window.stxConfig.userTypes}
                    onChange={handleChange}
                    value={values.type}
                    disabled={user && values.type == 1}
                    error={touched.type && Boolean(errors.type)}
                    helperText={touched.type && errors.type}
                />

                {PasswordSection}
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserFormModal;
