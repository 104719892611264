import React, { useEffect, useState } from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Alert, Grid, Typography
} from "@mui/material";

import { getAxios } from "utils";
import { useNavigate } from "react-router-dom";
import { imageUrl } from "utils";
import DefaultTextField from "components/DefaultTextField";
import ImageField from "../../../../components/ImageField";
import DefaultAutoCompleteField from "components/DefaultAutoCompleteField";
import DefaultCheckField from "components/DefaultCheckField";

function TeacherFormModal({ open, handleClose, teacher }) {
    const navigate = useNavigate();

    const [title, setTitle] = useState(teacher != null
        ? teacher.name
        : 'Novo Professor'
    );
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [loadingBranches, setLoadingBranches] = useState(false);
    const [error, setError] = useState(null);
    const [branches, setBranches] = useState([]);
    const [users, setUsers] = useState([]);

    const initialValues = {
        id: teacher?.id ?? '',
        userId: teacher?.userId ?? '',
        name: teacher?.name ?? '', 
        branch: teacher?.branch ?? '',
        email: teacher?.email ?? '',
        bio: teacher?.bio ?? '',
        image: teacher?.image ?? '',
        visible: teacher?.visible ?? true,
        images: []
    };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        try {
            if (teacher != null) {
                const uri = '/proxy/admin/teachers/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/teachers';
                await axiosInstance.post(uri, formData);
            }
            return true;
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return false;
            }
            setError('Erro interno');
            return false;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
        userId: yup.number().required(),
        email: yup.string().required(),
        branch: yup.number().required(),
        bio: yup.string().required(),
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue
    } = useFormik({
        initialValues,
        validationSchema,
        async onSubmit(values, { resetForm }) {
            if (await saveChanges(values)) {
                handleClose();
                resetForm();
            }
        }
    });

    useEffect(() => {
        if (teacher != null) setTitle(values.name);
    }, [teacher, values.name])

    const onChangeImage = (images) => {
        setFieldValue('images', images);
        setFieldValue('image', images[0].file)
    }
    
    const handleGetBranches = async () => {
        setLoadingBranches(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/branches";
        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            if (err.response.data.error.code == 404) {
                setError("Não há mais pacotes disponíveis")
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        setError(undefined);

        let branchesAux = response.data.result.branches.map(branch => ({
            label: branch.name,
            id: branch.id
        }));
        setBranches(branchesAux);
        setLoadingBranches(false);
    }

    useEffect(() => { if (open) handleGetBranches() }, [open]);
    
    const handleGetUsers = async () => {
        setLoadingUsers(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/users";
        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            if (err.response.data.error.code == 404) {
                setError("Não há mais pacotes disponíveis")
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        setError(undefined);

        let usersAux = response.data.result.users.map(user => ({
            label: user.name,
            id: user.id
        }));
        setUsers(usersAux);
        setLoadingUsers(false);
    }

    useEffect(() => { if (open) handleGetUsers() }, [open]);
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {error && error != '' &&
                    <Alert
                        severity="error"
                        onClose={() => setError(undefined)}
                    >
                        {error}
                    </Alert>
                }

                <ImageField
                    value={values.images}
                    onChange={onChangeImage}
                    initialValue={values.id && values.image && imageUrl(
                        window.stxConfig.imageTypes.teacherPoster,
                        values.id, 380, 192
                    )}
                    width={200}
                    height={250}
                />

                <DefaultTextField
                    required
                    label="Nome"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                />
                {!loadingBranches &&
                    <DefaultAutoCompleteField
                        label="Branch"
                        value={values.branch}
                        onChange={newValue => {
                            const value = newValue ? newValue.id : '';
                            setFieldValue('branch', value)
                        }}
                        options={branches}
                        error={touched.branch && Boolean(errors.branch)}
                        helperText={touched.branch && errors.branch}
                    />
                }
                {!loadingUsers &&
                    <DefaultAutoCompleteField
                        label="Usuário"
                        value={values.userId}
                        onChange={newValue => {
                            const value = newValue ? newValue.id : '';
                            setFieldValue('userId', value)
                        }}
                        options={users}
                        error={touched.userId && Boolean(errors.userId)}
                        helperText={touched.userId && errors.userId}
                    />
                }
                <DefaultTextField
                    required
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                />
                <DefaultCheckField
                    label="Visível"
                    checked={values.visible}
                    name='visible'
                    onChange={handleChange}
                />
                <DefaultTextField
                    label="Biografia"
                    name="bio"
                    onChange={handleChange}
                    multiline
                    value={values.bio}
                    error={touched.bio && Boolean(errors.bio)}
                    helperText={touched.bio && errors.bio}
                />
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TeacherFormModal;