/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import Logout from "Logout";
import PrivateRoute from "PrivateRoute";
import Users from "layouts/users";
import RelatoriosAssets from "layouts/RelatoriosAssets";
import Feedbacks from "layouts/Feedbacks";
import Planos from "layouts/planos";

// @mui icons
import Icon from "@mui/material/Icon";
import RelatoriosUsusarios from "layouts/RelatoriosUsuarios";
import VodPackages from "layouts/vodPackages";
import Screens from "layouts/screens";
import Screen from "layouts/screen";
import Lives from "layouts/lives";
import Catalogs from "layouts/catalogs";
import Storage from "layouts/bucket";
import Storages from "layouts/storages";
import Branches from "layouts/branches";
import EmailTemplates from "layouts/emailTemplates";
import EmailSenders from "layouts/emailSenders";
import EmailHistory from "layouts/emailHistory";
import Teachers from "layouts/teachers";
import UserClassHistory from "layouts/userClassHistory";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <PrivateRoute><Dashboard /></PrivateRoute>,
  },
  {
    type: "collapse",
    name: "Branches",
    key: "branches",
    icon: <Icon fontSize="small">alt_route</Icon>,
    route: "/branches",
    component: <PrivateRoute><Branches /></PrivateRoute>,
  },
  {
    type: "collapse",
    name: "CRM",
    key: "crm",
    icon: <Icon fontSize="small">person</Icon>,
    route: '/crm',
    collapse: [
      {
        name: "Usuários",
        key: "usuarios",
        icon: <Icon fontSize="small">group</Icon>,
        route: "/crm/usuarios",
        component: <PrivateRoute><Users /></PrivateRoute>,
      },
      {
        name: "Professores",
        key: "teachers",
        icon: <Icon fontSize="small">group</Icon>,
        route: "/crm/teachers",
        component: <PrivateRoute><Teachers /></PrivateRoute>,
      },
    ]
  },
  {
    type: "collapse",
    name: "Monetização",
    key: "monetization",
    icon: <Icon fontSize="small">monetization_on</Icon>,
    route: '/monetization',
    collapse: [
      {
        name: "Planos",
        key: "planos",
        icon: <Icon fontSize="small">subscriptions</Icon>,
        route: "/monetization/planos",
        component: <PrivateRoute><Planos /></PrivateRoute>,
      },
    ]
  },
  {
    type: "collapse",
    name: "CMS",
    key: "cms",
    icon: <Icon fontSize="small">video_settings</Icon>,
    route: '/cms',
    collapse: [
      {
        type: "collapse",
        name: "VOD",
        key: "vod",
        icon: <Icon fontSize="small">ondemand_video</Icon>,
        route: "/cms/vod",
        collapse: [
          {
            type: "collapse",
            name: "Catálogos",
            key: "catalogs",
            icon: <Icon fontSize="small">ondemand_video</Icon>,
            route: "/cms/vod/catalogs",
            component: <PrivateRoute><Catalogs /></PrivateRoute>,
          },
          {
            name: "Pacotes",
            key: "packages",
            icon: <Icon fontSize="small">inventory</Icon>,
            route: "/cms/vod/packages",
            component: <PrivateRoute><VodPackages /></PrivateRoute>,
          },
        ]
      },
      {
        type: "collapse",
        name: "Aulas ao vivo",
        key: "lives",
        icon: <Icon fontSize="small">ondemand_video</Icon>,
        route: "/cms/lives",
        component: <PrivateRoute><Lives /></PrivateRoute>,
      }
    ]
  },
  {
    type: "collapse",
    name: "Comunicação",
    key: "communication",
    icon: <Icon fontSize="small">chat_bubble</Icon>,
    route: '/communication',
    collapse: [
      {
        name: "Templates de Emails",
        key: "templates",
        icon: <Icon fontSize="small">view_quilt</Icon>,
        route: "/communication/templates",
        component: <PrivateRoute><EmailTemplates /></PrivateRoute>,
      },
      {
        name: "Emails Senders",
        key: "senders",
        icon: <Icon fontSize="small">send</Icon>,
        route: "/communication/senders",
        component: <PrivateRoute><EmailSenders /></PrivateRoute>,
      },
      {
        name: "Histórico de Emails",
        key: "emailHistory",
        icon: <Icon fontSize="small">history</Icon>,
        route: "/communication/emailHistory",
        component: <PrivateRoute><EmailHistory /></PrivateRoute>,
      },
    ]
  }, 
  {
    type: "collapse",
    name: "Aulas Particulares",
    key: "emailHistory",
    icon: <Icon fontSize="small">history</Icon>,
    route: "/userClassHistory",
    component: <PrivateRoute><UserClassHistory /></PrivateRoute>,
  },
  {
    type: "collapse",
    name: "Storage",
    key: "storage",
    icon: <Icon fontSize="small">storage</Icon>,
    route: "/storage",
    component: <PrivateRoute><Storages /></PrivateRoute>,
  },
  {
    name: "Storage",
    key: "storage",
    icon: <Icon fontSize="small">storage</Icon>,
    route: "/storage/:id/:bucket",
    component: <PrivateRoute><Storage /></PrivateRoute>,
  },
  {
    type: "collapse",
    name: "Relatórios",
    key: "reports",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: '/reports',
    collapse: [
      {
        name: "Feedbacks",
        key: "feedbacks",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/reports/feedbacks",
        component: <PrivateRoute><Feedbacks /></PrivateRoute>,
      },
      {
        name: "Relatorios Usuários",
        route: "/reports/relatorios-usuarios",
        key: "relatorios-usuarios",
        icon: <Icon fontSize="small">table_view</Icon>,
        component: <PrivateRoute><RelatoriosUsusarios /></PrivateRoute>
      },
      {
        name: "Relatórios Programas",
        route: "/reports/relatorios-assets",
        key: "relatorios-assets",
        icon: <Icon fontSize="small">table_view</Icon>,
        component: <PrivateRoute><RelatoriosAssets /></PrivateRoute>,
      },
    ]
  },
  {
    type: "collapse",
    name: "Telas",
    key: "screens",
    icon: <Icon fontSize="small">screenshot_monitor</Icon>,
    route: "/screens",
    component: <PrivateRoute><Screens /></PrivateRoute>,
  },
  {
    name: "Tela",
    key: "screen",
    route: "/screen/:id",
    component: <PrivateRoute><Screen /></PrivateRoute>,
  },
  {
    route: "/logout",
    component: <Logout />,
  },
  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sair",
    key: "sign-up",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/logout",
    component: <Logout />,
  },
];

export default routes;
