/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import moment from "moment";
import * as yup from 'yup';

moment.updateLocale('en', { invalidDate: 'Não definido' });

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  }
});

ReactDOM.render(
  <BrowserRouter>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </LocalizationProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
