import React, { useState } from "react";

import {
    Dialog, DialogContent, DialogTitle, Icon, Box, Button, Typography
} from "@mui/material";
import TeacherFormModal from "../TeacherFormModal";
import TitleValue from "components/TitleValue";
import { imageUrl } from "utils";

function TeacherModal({ open, handleClose, teacher }) {
    const { id, name, email, bio, branchName } = teacher

    const thumbUrl = imageUrl(
        window.stxConfig.imageTypes.teacherPoster,
        id, 380, 192
    );

    const [edit, setEdit] = useState(false);

    const handleEdit = () => setEdit(true);
    const handleStopEdit = () => {
        setEdit(false);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
        >
            <DialogTitle>{name}</DialogTitle>
            <DialogContent>
                <TeacherFormModal
                    open={edit}
                    handleClose={handleStopEdit}
                    teacher={teacher}
                />

                <img src={thumbUrl} alt="" width="200" height="250" />
                <br />

                <TitleValue title='Nome' value={name} />
                <TitleValue title='Email' value={email} />
                <TitleValue title='Branch' value={branchName} />

                <Typography variant="h2" component="h2">
                    Biografia
                </Typography>

                <Typography
                    variant="body2"
                    component='span'
                    style={{whiteSpace: 'pre-line'}}
                >{bio}</Typography>

                <Box display='flex' justifyContent='flex-end'>
                    <Button
                        variant="contained"
                        style={{ color: 'white' }}
                        onClick={handleEdit}
                        startIcon={<Icon>edit</Icon>}
                    >
                        Editar
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default TeacherModal;
