import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

function DefaultCheckField(props) {
    const { label, ...other } = props

    return label
        ? <FormGroup>
            <FormControlLabel
                label={label}
                {...other}
                control={<Checkbox {...other} />}
            />
        </FormGroup>
        : <Checkbox {...other} />
}

export default DefaultCheckField;
