import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

import { 
    Card, Table, TableBody, TableRow, TableCell, TablePagination 
} from "@mui/material";

import { getAxios } from "../../utils";

import BarLoader from "react-spinners/BarLoader";
import { createSearchParams, useNavigate } from 'react-router-dom';
import UserClassTile from "./components/UserClassTile";
import SearchBar from "components/SearchBar";
import TablePaginationActions from "components/TablePaginationActions";

const maxUserClasses = 10;

function UserClassHistory() {
    const [userClassHistory, setUserClassHistory] = useState([]);
    const [totalUserClasses, setTotalUserClasses] = useState(0);
    const [pageAssets, setPageAssets] = useState(0);
    const [erro, setErro] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [update, setUpdate] = useState(false);

    const navigate = useNavigate();
    
    const handleChangePage = (_, newPage) => setPageAssets(newPage);

    const handleGetUserClassHistory = async() => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/userClassHistory";
        let response;
        const params = {
            limitStart: pageAssets*maxUserClasses, 
            limit: maxUserClasses,
            search
        }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch(err) {
            console.error(err);

            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setErro("Erro interno");
            return;
        }
        
        if(!response.data.result) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setUserClassHistory(response.data.result.userClassHistory);
        setTotalUserClasses(response.data.result.total)
    }

    useEffect(async() => { 
        await handleGetUserClassHistory() 
        setUpdate(false);
    }, [update, pageAssets, search]);
    
    const handleSearch = async (value) => {
        setSearch(value);
        setPageAssets(0);
    } 

    const title = <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
    >
        <MDTypography variant="h6" color="white">
            Aulas Particulares
        </MDTypography>
    </MDBox>

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mt={4}>
                {!!erro && <MDAlert color="error">{erro}</MDAlert>}
                <Card>
                    {title}
                    <MDBox pt={3}>
                        <SearchBar label="Pesquisar" onSearch={handleSearch} />
                        <Table>
                            <MDBox component="thead">
                                <TableRow>
                                    <TableCell><strong>Id</strong></TableCell>
                                    <TableCell><strong>Usuário</strong></TableCell>
                                    <TableCell><strong>Professor</strong></TableCell>
                                    <TableCell><strong>Data</strong></TableCell>
                                    <TableCell><strong>Status</strong></TableCell>
                                    <TableCell><strong>Entrar</strong></TableCell>
                                </TableRow>
                            </MDBox>
                            <TableBody>{userClassHistory.map((userClass, index) =>
                                <UserClassTile
                                    key={`userClass_${index}`}
                                    userClass={userClass}
                                    updatedCallback={setUpdate}
                                />
                            )}</TableBody>
                        </Table>
                        {isLoading &&
                            <BarLoader
                                color="#ACC0F0" 
                                cssOverride={{ width: "100%" }}
                            />
                        }
                        <TablePagination 
                            rowsPerPageOptions={[maxUserClasses]}
                            component="div"
                            count={totalUserClasses}
                            rowsPerPage={maxUserClasses}
                            page={pageAssets}
                            onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}

export default UserClassHistory;
