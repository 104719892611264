import { DatePicker } from "@mui/x-date-pickers";
import MDBox from "components/MDBox";
import moment from "moment";

function DefaultDateField(props) {
    const {value, onChange, ...other} = props;

    return (
        <MDBox mt={1}>
            <DatePicker
                format="DD/MM/YYYY"
                value={value != '' ? moment(value) : null}
                onChange={(value) => onChange(moment(value).format('YYYY-MM-DD'))}
                slotProps={{ textField: { variant: 'standard', ...other }}}
                {...other}
            />
        </MDBox>
    )
}

export default DefaultDateField;
