import { Box } from "@mui/material";
import DefaultAutoCompleteField from "components/DefaultAutoCompleteField";
import { useEffect, useState } from "react";
import { getAxios } from "utils";

function TVShowFilter({ value, onChange }) {
    const [tvShows, setTvShows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleGetTVShows = async () => {
        setLoading(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/categories";
        const params = { isSerie: true }
        let response;

        try {
            response = await axiosInstance.get(uri, { params });
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            if (err.response.data.error.code == 404) {
                setError("Não há mais pacotes disponíveis")
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        setError(undefined);

        let tvShowsAux = response.data.result.categories.map(category => ({
            label: category.name,
            id: category.id
        }));
        setTvShows(tvShowsAux);
        setLoading(false);
    }

    useEffect(() => setLoading(false), [error]);

    return (
        <Box width={200}>
            <DefaultAutoCompleteField
                label="Categoria"
                loading={loading}
                value={value}
                onChange={(value) => onChange(value?.id)}
                options={tvShows}
                onOpen={handleGetTVShows}
            />
        </Box>
    )
}

export default TVShowFilter;