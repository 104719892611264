import React, { useEffect, useState } from 'react';
import {
    Box, CircularProgress, Collapse, Icon, 
    ListItemAvatar, ListItemButton, ListItemText
} from "@mui/material";

function ContentTile({ object, storageId, service, bucket, folder = '', onSelect, space=0 }) {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(true);

    const isFolder = object.slice(-1) == '/';
    const name = object.replace(folder, "").replace('/', "");

    const handleClick = (e) => {
        if (isFolder) setOpen(!open)
        else onSelect(`${storageId}/${bucket}/${object}`);
    };

    const handleGetBucket = async () => {
        if (service) {
            setLoading(true);
            setError(null);

            try {
                const response = await service.getAll(bucket, object);
                setItems(response);
            } catch (error) {
                setError('Erro Interno');
                setItems([])
            }

            setLoading(false);
        }
    }

    useEffect(() => { if (open) handleGetBucket() }, [open]);

    return (
        <Box ml={space}>
            <ListItemButton onClick={handleClick}>
                <ListItemAvatar>
                    <Icon>
                        {isFolder ? (open ? 'folder_open' : 'folder') : 'article'}
                    </Icon>
                </ListItemAvatar>
                <ListItemText primary={name} />
            </ListItemButton>
            {isFolder && (
                <Collapse in={open}>
                    {loading && items.length > 0
                        ? <CircularProgress />
                        : items.map((item, index) =>
                            <ContentTile
                                key={`content_${index}`}
                                storageId={storageId}
                                object={item}
                                bucket={bucket}
                                service={service}
                                folder={object}
                                onSelect={onSelect}
                                space={space + 2}
                            />
                        )}
                </Collapse>
            )}
        </Box>
    )
}

export default ContentTile;