import React from "react";

import { 
    Dialog, DialogContent, DialogTitle, ListItem, ListItemButton, ListItemText  
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function StorageModal({ open, handleClose, storage }) {
    const { id, name, buckets } = storage;

    const navigate = useNavigate();
    const handleClick = (bucket) => {
        const uri = encodeURI(`/storage/${id}/${bucket}`);
        return navigate(uri);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
        >
            <DialogTitle>{name}</DialogTitle>
            <DialogContent>
                {(buckets ?? []).map(((bucket, index) => ( 
                    <ListItem key={`bucket_${index}`}>
                        <ListItemButton onClick={() => handleClick(bucket)}>
                            <ListItemText primary={bucket} />
                        </ListItemButton>
                    </ListItem>
                 )))}
            </DialogContent>
        </Dialog>
    )
}

export default StorageModal;
