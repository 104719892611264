import React, { useEffect, useState } from "react";
import moment from "moment";
import * as yup from 'yup';
import { useFormik } from 'formik';

import {
    Button, Dialog, DialogContent, DialogTitle, 
    DialogActions, Alert
} from "@mui/material";

import { getAxios } from "utils";
import { useNavigate } from "react-router-dom";
import DefaultDateTimeField from "components/DefaultDateTimePicker";
import DefaultCheckField from "components/DefaultCheckField";
import DefaultAutoCompleteField from "components/DefaultAutoCompleteField";

moment.updateLocale('en', { invalidDate: 'Não definido' });

function UserPlanFormModal({ open, handleClose, userId, userPlan = null }) {
    const navigate = useNavigate();

    const [options, setOptions] = useState(['']);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const initialState = {
        optionId: '', contractEndDate: '', 
        allowCancel: false, allowRenewal: false
    };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }
        formData.append('userId', userId);

        try {
            if (userPlan != null) {
                const uri = '/proxy/admin/userOptions/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/userOptions';
                await axiosInstance.post(uri, formData);
            }
            return true;
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return false;
            } else if (err.response.data.error.message == "Forbidden") {
                setError('Esse cliente já possui o plano selecionado ativo.');
                return false;
            }
            setError('Erro interno');
            return false;
        }
    }

    const validationSchema = yup.object({
        optionId: yup.number().required('Campo obrigatório'),
        contractEndDate: yup.date().required('Campo obrigatório')
    });

    const {
        values,
        touched,
        errors,
        setFieldValue,
        handleSubmit,
        handleChange
    } = useFormik({
        initialValues: userPlan ?? initialState,
        validationSchema,
        async onSubmit(values) {
            if (await saveChanges(values)) handleClose();
        }
    });

    const handleGetPlanos = async () => {
        setLoading(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/options";
        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        setError(undefined);

        let optionsAux = response.data.result.options.map(option => ({
            label: option.name,
            id: option.idOption
        }));
        setOptions(optionsAux);
        setLoading(false);
    }

    useEffect(() => {
        if (open) handleGetPlanos();
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Novo Plano</DialogTitle>
            <DialogContent>
                {error && error != '' &&
                    <Alert
                        severity="error"
                        onClose={() => setError(undefined)}
                    >
                        {error}
                    </Alert>
                }
                {!loading && 
                    <DefaultAutoCompleteField
                        label="Plano"
                        disabled={!!userPlan}
                        value={values.optionId}
                        onChange={newValue => {
                            const value = newValue ? newValue.id : '';
                            setFieldValue('optionId', value)
                        }}
                        options={options}
                        error={touched.optionId && Boolean(errors.optionId)}
                        helperText={touched.optionId && errors.optionId}
                    />
                }
                <DefaultDateTimeField
                    label='Valido até'
                    value={values.contractEndDate}
                    onChange={(value) => setFieldValue(
                        'contractEndDate', moment(value).format('YYYY-MM-DD HH:mm:ss')
                    )}
                    error={touched.contractEndDate && Boolean(errors.contractEndDate)}
                    helperText={touched.contractEndDate && errors.contractEndDate}
                />
                <DefaultCheckField
                    label="Permitir cancelamento?"
                    required
                    name="allowCancel"
                    checked={values.allowCancel}
                    onChange={handleChange}
                />
                <DefaultCheckField
                    label="Permitir renovação?"
                    required
                    name="allowRenewal"
                    checked={values.allowRenewal}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserPlanFormModal;
