import React, { useEffect, useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

import {
    Card, Table, TableCell, TablePagination, TableBody, TableContainer, Button, Box
} from "@mui/material";
import TableRow from "@mui/material/TableRow";

import { getAxios } from "../../utils";

import { BarLoader } from "react-spinners";
import CatalogTile from "./components/CatalogTile";
import { createSearchParams, useNavigate } from "react-router-dom";
import CatalogFormModal from "./components/CatalogFormModal";
import SearchBar from "components/SearchBar";
import TablePaginationActions from "components/TablePaginationActions";

const maxCatalogs = 10;

function Catalogs() {
    const [catalogs, setCatalogs] = useState([]);
    const [pageAssets, setPageAssets] = useState(0);
    const [erro, setErro] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [totalCatalogs, setTotalCatalogs] = useState(1);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(true);
    const [search, setSearch] = useState('');

    const navigate = useNavigate();

    const handleChangePage = (_, newpage) => setPageAssets(newpage);

    const handleGetCatalogs = async () => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/vodPubCatalogs";
        let response;
        const params = {
            limitStart: pageAssets * maxCatalogs,
            limit: maxCatalogs,
            search
        }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setErro("Erro interno");
            return;
        }

        if (!response.data) {
            setErro("Erro interno");
            return;
        }

        setErro(undefined);
        setLoading(false);
        setCatalogs(response.data.result.catalogs);
        setTotalCatalogs(response.data.result.total)
    }

    useEffect(async () => {
        await handleGetCatalogs();
        setUpdate(false);
    }, [pageAssets, update, search])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setUpdate(true);
    }

    const title = <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
    >
        <MDTypography variant="h6" color="white">
            Pacotes
        </MDTypography>
    </MDBox>;

    const handleSearch = async (value) => {
        setSearch(value);
        setPageAssets(0);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <CatalogFormModal
                open={open}
                handleClose={handleClose}
            />
            <Box
                display='flex'
                justifyContent='flex-end'
            >
                <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={handleOpen}
                >
                    Adicionar +
                </Button>
            </Box>
            <MDBox mt={4}>
                {!!erro && <MDAlert color="error">{erro}</MDAlert>}
                <Card>
                    {title}
                    <MDBox pt={3}>
                        <SearchBar label="Pesquisar" onSearch={handleSearch} />
                        <TableContainer>
                            <Table>
                                <MDBox component="thead">
                                    <TableRow>
                                        <TableCell><strong>ID</strong></TableCell>
                                        <TableCell><strong>Nome</strong></TableCell>
                                        <TableCell width="5"></TableCell>
                                    </TableRow>
                                </MDBox>
                                <TableBody>
                                    {catalogs.map((catalog, index) =>
                                        <CatalogTile
                                            key={`catalog_${index}`}
                                            catalog={catalog}
                                            updatedCallback={setUpdate}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                            {isLoading &&
                                <BarLoader
                                    color="#ACC0F0"
                                    cssOverride={{ width: "100%" }}
                                />
                            }
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[maxCatalogs]}
                            component="div"
                            count={totalCatalogs}
                            rowsPerPage={maxCatalogs}
                            page={pageAssets}
                            onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}

export default Catalogs;