import { useState } from "react";
import { FormControl, Icon, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import StorageFormModal from "./StorageFormModal";

function StorageField(props) {
    const { onSelect, label, ...other } = props;

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSelect = (object) => {
        onSelect(object);
        handleClose();
    }

    return (
        <FormControl sx={{ mt: 2, width: 1 }} variant="outlined">
            <StorageFormModal open={open} onClose={handleClose} onSelect={handleSelect}/>
            <InputLabel>{label}</InputLabel>
            <OutlinedInput
                variant="outlined"
                size="small"
                readOnly
                onClick={handleOpen}
                startAdornment={
                    <InputAdornment position="start">
                        <Icon>storage</Icon>
                    </InputAdornment>
                }
                {...other}
            />
        </FormControl>
    );
}

export default StorageField;