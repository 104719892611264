import React, { useState } from 'react';
import { Icon, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import WarningModal from 'components/WarningModal';

function BucketTile({ object, onClick, updatedCallback, service, bucket, folder }) {
    const [deleteWarning, setDeleteWarning] = useState(false);
    
    const isFolder = object.slice(-1) == '/';
    const name = object.replace(folder, "").replace('/', "");

    const handleClick = (e) => {
        e.preventDefault();

        onClick(object);
    }

    const handleDelete = () => {
        try {
            service.deleteFile(bucket, object);
            updatedCallback(true);
        } catch (error) {
            console.error(error);
        }
    }
    
    const handleOpenDeleteWarning = () => setDeleteWarning(true);
    const handleCloseDeleteWarning = () => setDeleteWarning(false);

    let deleteButton = <IconButton onClick={handleOpenDeleteWarning}>
        <Icon>delete</Icon>
    </IconButton>

    return (
        <>
            <WarningModal
                open={deleteWarning}
                handleClose={handleCloseDeleteWarning}
                handleAccept={handleDelete}
                handleDismiss={handleCloseDeleteWarning}
                title='Remover arquivo'
                message={`Tem certeza que deseja remover o arquivo ${name}?`}
            />
            <ListItem
                onClick={isFolder ? handleClick : null}
                secondaryAction={isFolder ? null : deleteButton}
            >
                <ListItemAvatar>
                    <Icon>{isFolder ? 'folder' : 'article'}</Icon>
                </ListItemAvatar>
                <ListItemButton onClick={isFolder ? onClick : null}>
                    <ListItemText primary={name} />
                </ListItemButton>
            </ListItem>
        </>
    )
}

export default BucketTile;