import React, { useEffect, useState } from "react";
import moment from "moment";
import * as yup from 'yup';

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography, Box
} from "@mui/material";

import { getAxios } from "utils";
import DefaultTextField from "components/DefaultTextField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import DefaultCheckField from "components/DefaultCheckField";
import DefaultSelectField from "components/DefaultSelectField";

moment.updateLocale('en', { invalidDate: 'Não definido' });

function BranchFormModal({ open, handleClose, branch = null }) {
    const navigate = useNavigate();

    const [paymentFields, setPaymentFields] = useState([]);
    const [title, setTitle] = useState(branch != null
        ? branch.name 
        : 'Nova Branch'
    );
   
    const initialValues = {
        name: branch?.name ?? '',
        vod: branch?.vod ?? false,
        catchup: branch?.catchup ?? false,
        oauthEndpoint: branch?.oauth.endpoint ?? '', 
        clientId: branch?.oauth.clientId ?? '', 
        clientSecret: branch?.oauth.clientSecret ?? '', 
        profiles: branch?.profiles ?? '', 
        paymentType: branch?.payment?.type ?? 0,
        paymentValue: branch?.payment?.value ?? null,
        subscriptionWarning: branch?.payment.subscriptionWarning ?? ''
    };

    const [havePayment, setHavePayment] = useState(
        initialValues.paymentType != 0
    );

    const saveChanges = async (values) => {
        const formData = new FormData();

        const axiosInstance = getAxios();

        for (let [key, value] of Object.entries(values)) {
            if (typeof value === 'object'
                && !(value instanceof File)
                && !(value instanceof Blob)
            ) value = JSON.stringify(value); 
            formData.append(key, value);
        }

        try {
            if (branch != null) {
                const uri = '/proxy/admin/branches/edit';
                formData.append('id', branch.id)
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/branches';
                await axiosInstance.post(uri, formData);
            }
            handleClose();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
        profiles: yup.string().required()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue
    } = useFormik({
        initialValues,
        onSubmit: saveChanges,
        validationSchema,
    });

    useEffect(
        () => branch && setTitle(values.name),
        [branch, values.name]
    )

    useEffect(() => {
        if (values.paymentType != 0) {
            const fields = window.stxConfig.paymentTypes
                .find(type => type.value == values.paymentType)
                ?.fields;

            setPaymentFields(fields);
            setHavePayment(true)
        }
    }, [values.paymentType])

    useEffect(() => {
        if (!havePayment) {
            setFieldValue('paymentType', 0);
            setFieldValue('paymentValue', null);
        }
    }, [havePayment])

    const handleChangeType = (e, value) => {
        e.preventDefault();

        const type = value.props.value;
        setFieldValue('paymentType', type);

        const fields = window.stxConfig.paymentTypes
            .find(paymentType => paymentType.value == type)
            ?.fields;

        setFieldValue(
            'value',
            Object.fromEntries(fields.map(item => [item.name, item.default ?? '']))
        )
    }

    const createField = (field, index) => { 
        const { name, ...args } = field;

        const key = `field_${index}`
        const value = values.paymentValue ? values.paymentValue[name] : null;
        const formattedName = `paymentValue.${name}`;

        return <DefaultTextField
            key={key}
            value={value}
            onChange={handleChange}
            name={formattedName}
            {...args}
        />
    }

    const oauthSection = (
        <>
            <Typography>Oauth</Typography>
            <DefaultTextField
                label="Endpoint"
                value={values.oauthEndpoint}
                name='oauthEndpoint'
                onChange={handleChange}
                error={touched.oauthEndpoint && Boolean(errors.oauthEndpoint)}
                helperText={touched.oauthEndpoint && errors.oauthEndpoint}
            />
            <DefaultTextField
                label="Client Id"
                value={values.clientId}
                name='clientId'
                onChange={handleChange}
                error={touched.clientId && Boolean(errors.clientId)}
                helperText={touched.clientId && errors.clientId}
                required={values.oauthEndpoint != ''}
            />
            <DefaultTextField
                label="Client Secret"
                value={values.clientSecret}
                name='clientSecret'
                onChange={handleChange}
                error={touched.clientSecret && Boolean(errors.clientSecret)}
                helperText={touched.clientSecret && errors.clientSecret}
                required={values.oauthEndpoint != ''}
            />
        </>
    )

    const paymentSection = (
        <>
            <Typography>Pagamento</Typography>
            <DefaultSelectField
                value={values.paymentType}
                label='Tipo'
                name='paymentType'
                onChange={handleChangeType}
                items={window.stxConfig.paymentTypes}
                error={touched.paymentTyp && Boolean(errors.paymentType)}
                helperText={touched.paymentType && errors.paymentType}
            />
            {paymentFields?.map(createField)}
            <DefaultTextField
                label="Período de alerta de assinatura"
                value={values.subscriptionWarning}
                name='subscriptionWarning'
                type='number'
                onChange={handleChange}
                error={touched.subscriptionWarning && Boolean(errors.subscriptionWarning)}
                helperText={touched.subscriptionWarning && errors.subscriptionWarning}
            />
        </>
    );
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DefaultTextField
                    label="Nome"
                    value={values.name}
                    name='name'
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    required
                />
                <DefaultTextField
                    label="Máximo de perfis"
                    value={values.profiles}
                    name='profiles'
                    type='number'
                    onChange={handleChange}
                    error={touched.profiles && Boolean(errors.profiles)}
                    helperText={touched.profiles && errors.profiles}
                    required
                />
                <DefaultCheckField
                    label="VOD"
                    checked={values.vod}
                    name='vod'
                    onChange={handleChange}
                />
                <DefaultCheckField
                    label="Catchup"
                    checked={values.catchup}
                    name='catchup'
                    onChange={handleChange}
                />
                <Box mt={3}>{oauthSection}</Box>
                <DefaultCheckField
                    label="Terá pagamento"
                    checked={havePayment}
                    onChange={(value) => setHavePayment(value.target.checked)}
                />
                {havePayment &&
                    <Box mt={3}>{paymentSection}</Box>
                }
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default BranchFormModal;
