import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";

import { CircularProgress, Dialog, DialogContent, DialogTitle, List } from "@mui/material";

import { getAxios } from "../../utils";

import { createSearchParams, useNavigate } from 'react-router-dom';
import StorageTile from "./StorageTile";

function StorageFormModal({ open, onClose, onSelect }) {
    const [storages, setStorages] = useState([]);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();


    const handleGetStorages = async () => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/storage";
        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data.result) {
            setError("Erro interno");
            return;
        }

        setError(undefined);
        setLoading(false);
        setStorages(response.data.result.storages);
    }

    useEffect(async () => {
        await handleGetStorages()
    }, []);

    let storageList = <List>
        {storages.map((storage, index) => <StorageTile
            key={`storage_${index}`}
            storage={storage}
            onSelect={onSelect}
        />)}
    </List>

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Selecione um Arquivo</DialogTitle>
            <DialogContent>
                {loading
                    ? <CircularProgress />
                    : storageList
                }
            </DialogContent>
        </Dialog>
    )
}

export default StorageFormModal;