import React, { useState } from 'react';

import { Collapse, Icon, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BucketTile from './BucketTile';

function StorageTile({ storage, onSelect }) {
    const [open, setOpen] = useState(false);
    const handleClick = () => setOpen(!open);

    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    <Icon>storage</Icon>
                </ListItemIcon>
                <ListItemText primary={storage.name} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open}>
                {storage.buckets.map((bucket, index) => 
                    <BucketTile 
                        key={`bucket_${index}`}
                        bucket={bucket} 
                        storage={storage}
                        onSelect={onSelect}
                    />
                )}
            </Collapse>
        </>
    )
}

export default StorageTile;