import React, { useState } from 'react';

import { TableCell, TableRow } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import StorageModal from '../StorageModal';

function StorageTile({ storage }) {
    const [open, setOpen] = useState(false);
    const { id, name } = storage

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <StorageModal open={open} handleClose={handleClose} storage={storage} />
            <TableRow onClick={handleOpen} style={{ cursor: 'pointer' }}>
                <TableCell scope="row">{id}</TableCell>
                <TableCell scope="row">{name}</TableCell>
            </TableRow>
        </>
    )
}

export default StorageTile;