import {
    CompleteMultipartUploadCommand, CreateMultipartUploadCommand, 
    DeleteObjectCommand, ListObjectsV2Command, S3Client, UploadPartCommand
} from "@aws-sdk/client-s3";

export default class S3Service {
    constructor(accessKeyId, secretAccessKey, endpoint) {
        this.client = new S3Client({
            apiVersion: 'latest',
            region: 'us-west-2',
            credentials: {
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey,
            },
            endpoint: endpoint,
            forcePathStyle: true,
        });
    }

    async getAll(bucket, folder = '') {
        const command = new ListObjectsV2Command({
            Bucket: bucket,
            Delimiter: "/",
            Prefix: folder
        });

        const { Contents, CommonPrefixes } = await this.client.send(command);
        const contents = Contents?.map(
            content => content.Key
        ) ?? [];
        const commonPrefixes = CommonPrefixes?.map(
            commonPrefixe => commonPrefixe.Prefix
        ) ?? [];
        return commonPrefixes.concat(contents);
    }

    async uploadFile(bucket, key, file, setProgress = (progress) => {}) {
        const command = new CreateMultipartUploadCommand({
            Bucket: bucket,
            Key: key,
        });
        const createMultipartUploadResponse = await this.client.send(command);

        const uploadId = createMultipartUploadResponse.UploadId;
        const partSize = 5 * 1024 * 1024;

        const totalParts = Math.ceil(file.size / partSize);
        let completedParts = [];
        
        for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
            const start = (partNumber - 1) * partSize;
            const end = Math.min(start + partSize, file.size);
            const part = file.slice(start, end);

            const uploadPartCommand = new UploadPartCommand({
                Bucket: bucket,
                Key: key,
                UploadId: uploadId,
                PartNumber: partNumber,
                Body: part,
            });

            const uploadPartResponse = await this.client.send(uploadPartCommand);
            completedParts.push({ ETag: uploadPartResponse.ETag, PartNumber: partNumber })
            const progress = Math.round((partNumber / totalParts) * 100);
            setProgress(progress)
        }

        const completeMultipartUploadCommand = new CompleteMultipartUploadCommand({
            Bucket: bucket,
            Key: key,
            UploadId: uploadId,
            MultipartUpload: { Parts: completedParts },
        });

        await this.client.send(completeMultipartUploadCommand);
    }

    async deleteFile(bucket, file) {
        const command = new DeleteObjectCommand({
            Bucket: bucket,
            Key: file,
        })

        return await this.client.send(command);
    }
}
