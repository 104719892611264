import { Avatar, Box, IconButton, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { imageUrl } from 'utils';
import TitleValue from 'components/TitleValue';
import ProfileFormModal from '../ProfileFormModal';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAxios } from 'utils';
import WarningModal from 'components/WarningModal';
import { useNavigate } from 'react-router-dom';

function ProfileTile ({profile, userId, updateCallBack}) {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [deleteWarning, setDeleteWarning] = useState(false);
    
    const access =  profile.kidProfile
        ? 'Somente conteúdo kids'
        : profile.maximumMoralityLevel == 0
            ? "Todo conteúdo"
            : `Conteúdo até ${profile.maximumMoralityLevel} ${profile.maximumMoralityLevel == 1 ? 'ano' : 'anos'}`;
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        updateCallBack(true);
    }

    const handleDelete = async (e) => {
        e.preventDefault();

        const axiosInstance = getAxios();
        const uri = '/crm/admin/profile';
        const params = {
            userId,
            id: profile.id
        }

        try {
            await axiosInstance.delete(uri, { params });
            updateCallBack(true);
            handleCloseDeleteWarning();
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const handleOpenDeleteWarning = () => setDeleteWarning(true);
    const handleCloseDeleteWarning = () => setDeleteWarning(false);

    return (
        <>
            <WarningModal
                open={deleteWarning}
                handleClose={handleCloseDeleteWarning}
                handleAccept={handleDelete}
                handleDismiss={handleCloseDeleteWarning}
                title='Deletar Perfil'
                message={`Tem certeza que deseja deletar o perfil ${profile.name}?`}
            />

            <ProfileFormModal 
                profile={profile} 
                open={open} 
                handleClose={handleClose} 
                userId={userId}
            />
            <ListItem>
                <ListItemAvatar>
                    <Avatar 
                        src={imageUrl(
                            window.stxConfig.imageTypes.avatar, 
                            profile.avatarid, 
                            50, 
                            50
                        )}
                    />
                </ListItemAvatar>
                <ListItemText primary={profile.name} 
                    secondary={
                        <React.Fragment>
                            <TitleValue title='Padrão' value={profile.default ? 'Sim' : 'Não'} />
                            <TitleValue title='Acesso' value={access} />
                        </React.Fragment>
                    }
                />
                <IconButton edge='end' aria-label='edit' onClick={handleOpen}>
                    <EditIcon />
                </IconButton>
                {!profile.default && 
                    <IconButton edge='end' aria-label='edit' onClick={handleOpenDeleteWarning}>
                        <DeleteIcon />
                    </IconButton>
                }
            </ListItem>
        </>
    )
}

export default ProfileTile;