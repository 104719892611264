import { useFormikContext } from "formik";
import StorageField from "../../../../components/StorageField";
import DefaultTextField from "components/DefaultTextField";

function MaterialTile({index}) {
    const {
        values,
        touched,
        errors,
        setFieldValue,
        handleChange
    } = useFormikContext();

    let _materials = values.materials,
        _material = values.materials[index],
        _errors = errors.materials?.length ? errors.materials[index] : null,
        _touched = touched.materials?.length ? touched.materials[index] : null;

    const handleChangePath = (value) => {
        let materials = _materials;
        materials[index].path = value
        setFieldValue("materials", materials);
    }

    return (
        <>
            <DefaultTextField
                label="Nome"
                name={`materials[${index}].name`}
                onChange={handleChange}
                value={_material.name}
                error={_touched?.name && Boolean(_errors?.name)}
                helperText={_touched?.name && _errors?.name}
            />
            <StorageField 
                label="Caminho"
                value={_material.path}
                onSelect={handleChangePath}
                error={_touched?.path && Boolean(_errors?.path)}
                helperText={_touched?.path && _errors?.path}
            /> 
        </>
    )
}

export default MaterialTile;