import React, { useState } from "react";

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Alert, CircularProgress, Box
} from "@mui/material";
import CircularProgressWithLabel from "components/CircularProgressWithLabel";
import DefaultTextField from "components/DefaultTextField";

function BucketFormModal({ open, handleClose, service, bucket, folder }) {
    const [error, setError] = useState(undefined);
    const [file, setFile] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [newFolder, setNewFolder] = useState('');

    const handleSubmit = async (e) => {
        setLoading(true);
        setProgress(0);
        e.preventDefault();

        const key = folder + (newFolder && newFolder + '/') + file.name
        try {
            await service.uploadFile(bucket, key, file, setProgress);
            handleClose();
        } catch (error) {
            setError('Erro Interno')
        }
        setLoading(false);
    }

    const handleFile = (e) => setFile(e.target.files[0]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Adicionar Arquivo</DialogTitle>
            <DialogContent>
                {error && error != '' &&
                    <Alert
                        severity="error"
                        onClose={() => setError(undefined)}
                    >
                        {error}
                    </Alert>
                }
                <DefaultTextField
                    label="Nova Pasta"
                    value={newFolder}
                    onChange={(e) => setNewFolder(e.target.value)}
                />
                <input type="file" onChange={handleFile} disabled={loading} />
                {loading && <Box 
                        mt={2} 
                        display={'flex'} 
                        justifyContent={'center'}
                    >
                        <CircularProgressWithLabel value={progress} />
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button disabled={loading} onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default BucketFormModal;