/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { getAccessToken } from "utils";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import { Link, useNavigate } from "react-router-dom";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import RelatoriosAssets from "./components/RelatoriosAssets";
import RelatoriosPoucoAssistidos from "./components/RelatoriosPoucoAssistidos";
import RelatoriosUsusarios from "./components/RelatoriosUsuarios";
import Feedbacks from "./components/Feedbacks";

function Dashboard() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox >
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
                <Link to="/relatorios-assets"><RelatoriosAssets /></Link>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
              <Link to="/relatorios-assets"><RelatoriosPoucoAssistidos /></Link>
            </Grid> */}
            <Grid item xs={12} md={6} lg={6}>
              <Link to="/feedbacks"><Feedbacks /></Link>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container>
            <Grid item xs={12} md={12} lg={8}>
              <Link to="/relatorios-usuarios"><RelatoriosUsusarios /></Link>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
