import React, { useEffect, useState } from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Alert
} from "@mui/material";

import { getAxios } from "utils";
import { useNavigate } from "react-router-dom";
import DefaultTextField from "components/DefaultTextField";
import DefaultAutoCompleteField from "components/DefaultAutoCompleteField";
import DefaultDateTimeField from "components/DefaultDateTimePicker";
import ImageField from "../../../../components/ImageField";

function LiveFormModal({ open, handleClose, live }) {
    const navigate = useNavigate();

    const [title, setTitle] = useState(live != null
        ? live.title
        : 'Nova Aula Ao Vivo'
    );
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [teachers, setTeachers] = useState([]);

    const initialState = {
        title: '', date: '', teacherId: '', description: '', thumb: ''
    };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        try {
            if (live != null) {
                const uri = '/proxy/admin/lives/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/lives';
                await axiosInstance.post(uri, formData);
            }
            return true;
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return false;
            }
            setError('Erro interno');
            return false;
        }
    }

    const validationSchema = yup.object({
        title: yup.string().required(),
        date: yup.string().required(),
        teacherId: yup.number().required()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue
    } = useFormik({
        initialValues: live ?? initialState,
        validationSchema,
        async onSubmit(values, { resetForm }) {
            if (await saveChanges(values)) {
                handleClose();
                resetForm();
            }
        }
    });

    useEffect(() => {
        if (live != null) setTitle(values.title);
    }, [live, values.title])

    const handleGetTeachers = async () => {
        setLoading(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/teachers";
        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            if (err.response.data.error.code == 404) {
                setError("Não há mais pacotes disponíveis")
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        setError(undefined);

        let teachersAux = response.data.result.teachers.map(teacher => ({
            label: teacher.name,
            id: teacher.id
        }));
        setTeachers(teachersAux);
        setLoading(false);
    }

    useEffect(() => { if (open) handleGetTeachers() }, [open])

    const onChangeImage = (images) => {
        setFieldValue('images', images);
        setFieldValue('thumb', images[0].file)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {error && error != '' &&
                    <Alert
                        severity="error"
                        onClose={() => setError(undefined)}
                    >
                        {error}
                    </Alert>
                }

                <ImageField
                    value={values.images}
                    onChange={onChangeImage}
                />

                <DefaultTextField
                    required
                    label="Título"
                    name="title"
                    onChange={handleChange}
                    value={values.title}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                />
                {!loading &&
                    <DefaultAutoCompleteField
                        required
                        label="Professor"
                        value={values.teacherId}
                        onChange={newValue => {
                            const value = newValue ? newValue.id : '';
                            setFieldValue('teacherId', value)
                        }}
                        options={teachers}
                        error={touched.teacherId && Boolean(errors.teacherId)}
                        helperText={touched.teacherId && errors.teacherId}
                    />
                }
                <DefaultDateTimeField
                    required
                    value={values.date}
                    label="Data"
                    onChange={val => setFieldValue('date', val)}
                />
                <DefaultTextField
                    label="Descrição"
                    name="description"
                    onChange={handleChange}
                    multiline
                    value={values.description}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                />
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default LiveFormModal;