import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import RelatorioAsset from "./RelatorioAsset";
import RelatoriosPoucoAssistidos from "layouts/RelatoriosPoucoAssistidos";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { TableHead } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { TableCell }from "@mui/material";
import TableRow from "@mui/material/TableRow";

import {TablePagination} from "@mui/material";
import { getAxios, getAccessToken, Relatorios } from "../../utils";

import { BarLoader } from "react-spinners";
import { createSearchParams, useNavigate } from "react-router-dom";

function RelatoriosAssets() {
    const [relatorios, setRelatorios] = useState({});
    const [erro, setErro] = useState(undefined);
    const [pageAssets, setPageAssets] = useState(0);
    const [isLoading, setLoaging] = useState(true);
    const [totalAssets, setTotalAssets] = useState(1);

    const handleChangePage = (event, newpage) => {
        setPageAssets(newpage);
    }
    const navigate = useNavigate();

    const handleGetRelatorios = async() => {
        const axiosInstance = getAxios();

        const uri = "/proxy/relatorioConteudo";
        let response;

        try {
            response = await axiosInstance.get(uri, {  params: { limitStart: pageAssets*5, limit: 5 }  });
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setErro("Erro interno");
            return;
        }
        
        if(!response.data) {
            setErro("Erro interno");
            return;
        }

        setTotalAssets(response.data.result.total)
        setErro(undefined);
        setLoaging(false);
        setRelatorios(response.data.result);
    }

    useEffect(async() => {
        setRelatorios({});
        return await handleGetRelatorios();
    }, [pageAssets])

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mt={8}>
                <MDBox mb={3}>
                    {!!erro && <MDAlert color="error">{erro}</MDAlert>}
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Lista de programas
                                    </MDTypography>
                                </MDBox>
                                <MDBox pt={3}>
                                    <TableContainer>
                                        <Table>
                                            <MDBox component="thead">
                                                <TableRow>
                                                    <TableCell><strong>Título</strong></TableCell>
                                                    <TableCell><strong>Episódios</strong></TableCell>
                                                    <TableCell><strong>Duração</strong></TableCell>
                                                </TableRow>
                                            </MDBox>
                                            {isLoading &&
                                                <BarLoader
                                                    color="#ACC0F0" 
                                                    cssOverride={{ width: "100%" }}
                                                />
                                            }
                                            <TableBody>
                                            {Object.keys(relatorios).map((relatorioIndice) => {
                                                if(relatorioIndice === "newAuthToken" || relatorioIndice === "total") {
                                                    return;
                                                }
                                        
                                                return <TableRow key={relatorioIndice}><RelatorioAsset relatorio={relatorios[relatorioIndice]}/></TableRow>
                                            })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination 
                                        rowsPerPageOptions={[5]}
                                        component="div"
                                        count={totalAssets}
                                        rowsPerPage={5}
                                        page={pageAssets}
                                        onPageChange={handleChangePage}
                                    />
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <RelatoriosPoucoAssistidos />
        </DashboardLayout>
    )
}

export default RelatoriosAssets;
