import { Dialog, DialogContent, DialogTitle, Link } from "@mui/material"
import TitleValue from "components/TitleValue";
import moment from "moment";

function UserClassModal({ open, handleClose, userClass }) {
    const { userName, teacherName, date, status, records } = userClass;
    const formattedDate = moment(date).format('DD/MM/YYYY HH:mm:ss')

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
        >
            <DialogTitle>Aula Particular</DialogTitle>
            <DialogContent>
                <TitleValue title="Usuário" value={userName} />
                <TitleValue title="Professor" value={teacherName} />
                <TitleValue title="Data" value={formattedDate} />
                <TitleValue title="Status" value={status} />
                {records && <a href={records.url} target="_blank">Ver Gravação</a>}
            </DialogContent>
        </Dialog>
    )
}

export default UserClassModal;
