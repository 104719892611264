import React, { useState } from 'react';

import { TableCell, TableRow } from "@mui/material";
import LiveModal from '../LiveModal';
import { sqlToDateTime } from 'utils';

function LiveTile({ live, updatedCallback }) {
    const { id, title, date, active } = live
    const activeName = active ? 'Sim' : 'Não';
    
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        updatedCallback(true);
    };

    return (
        <>
            <LiveModal open={open} handleClose={handleClose} live={live} />
            <TableRow onClick={handleOpen} style={{ cursor: 'pointer' }}>
                <TableCell scope="row">{id}</TableCell>
                <TableCell scope="row">{title}</TableCell>
                <TableCell scope="row">{sqlToDateTime(date)}</TableCell>
                <TableCell scope="row">{activeName}</TableCell>
            </TableRow>
        </>
    )
}

export default LiveTile;