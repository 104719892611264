import { useEffect, useState } from "react";
import { Formik } from 'formik';
import * as yup from 'yup';

import {
    Alert, Box, Button, Dialog, DialogActions,
    DialogContent, DialogTitle, Tab, Tabs
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { getAxios } from "utils";
import MetaDataTab from "./MetaDataTab";
import MediaTab from "./MediaTab";
import ImagesTab from "./ImagesTab";
import MaterialTab from "./MaterialTab";
import moment from "moment";

function AssetFormModal({ open, onClose, asset, catalogId = '' }) {
    const [page, setPage] = useState(0);
    const [error, setError] = useState(undefined);

    const navigate = useNavigate();
    const handleChangePage = (_, newPage) => setPage(newPage);

    const initialValues = {
        id: asset?.id ?? '',
        catalog: catalogId,
        externalId: asset?.externalId ?? '',
        adult: asset?.adult ?? false,
        downloadable: asset?.downloadable ?? false,
        language: asset?.language ?? '',
        mLevel: asset?.mLevel ?? 0,
        title: asset?.title ?? '',
        endDate: asset?.endDate ?? '',
        startDate: asset?.startDate ?? '',
        language: asset?.language ?? 'por',
        genre: asset?.genre ?? '',
        DURATION: asset?.DURATION ?? 0,
        RELEDATE: asset?.RELEDATE ?? 0,
        CSASNAME: asset?.CSASNAME ?? 0,
        SYNO600C: asset?.SYNO600C ?? 0,
        coverImage: [],
        cover: asset?.cover ?? '',
        posterImage: [],
        poster: asset?.poster ?? '',
        media: asset?.media ?? '',
        materials: asset?.materials ?? []
    };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (let [key, value] of Object.entries(values)) {
            if (typeof value === 'object'
                && !(value instanceof File)
                && !(value instanceof Blob)
            ) value = JSON.stringify(value); 
            formData.append(key, value);
        }

        try {
            if (asset != null) {
                const uri = '/proxy/admin/assets/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/assets';
                await axiosInstance.post(uri, formData);
            }
            return true;
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return false;
            }
            setError('Erro interno');
            return false;
        }
    }

    const validationSchema = yup.object({
        title: yup.string().required(),
        genre: yup.string().required(),
        mLevel: yup
            .number()
            .required()
            .max(21, 'Idade máxima não pode ser maior que 21')
            .min(0, 'Idade máxima não pode ser menor que 0 (Livre)'),
        materials: yup.array().of(
            yup.object().shape({
                name: yup.string().required(),
                path: yup.string().required()
            })
        ),
        startDate: yup.date().required(),
        endDate: yup
            .string()
            .test({
                name: 'valid date',
                test(value, ctx) {
                    const endDate = moment(value);
                    const startDate = moment(yup.ref('startDate'));
                    return endDate.isAfter(startDate)
                        ? true
                        : ctx.createError({ message: 'Data inválida' })
                }
            }).required(),
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
                if (await saveChanges(values)) {
                    onClose();
                    resetForm();
                }
            }}
        >
            {({ values, handleSubmit }) => (
                <Dialog
                    open={open}
                    fullWidth
                >
                    <DialogTitle>{asset != null ? values.title : 'Novo Asset'}</DialogTitle>
                    <DialogContent>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={page}
                                onChange={handleChangePage}
                            >
                                <Tab label="Metadata" />
                                <Tab label="Imagens" />
                                <Tab label="Media" />
                                <Tab label="Material" />
                            </Tabs>
                        </Box>
                        {error && error != '' &&
                            <Alert
                                severity="error"
                                onClose={() => setError(undefined)}
                            >
                                {error}
                            </Alert>
                        }
                        <MetaDataTab index={0} value={page} />
                        <ImagesTab index={1} value={page} />
                        <MediaTab index={2} value={page} />
                        <MaterialTab index={3} value={page} />
                    </DialogContent>
                    <DialogActions>
                        <Button color="warning" onClick={onClose}>Cancelar</Button>
                        <Button onClick={handleSubmit}>Salvar</Button>
                    </DialogActions>
                </Dialog >
            )}
        </Formik>
    )
}

export default AssetFormModal;