import { Button, Divider, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useFormikContext } from "formik";
import { Fragment } from "react";
import MaterialTile from "./MaterialTile";

function MaterialTab({ value, index }) {
    const {
        values,
        setFieldValue,
    } = useFormikContext();

    const handleAddMaterial = () => {
        const aux = values.materials;
        aux.push({ id: '', name: '', path: ''});
        setFieldValue('materials', aux);
    }

    const addMaterialButton = <Button
        onClick={handleAddMaterial}
        startIcon={<Icon>add</Icon>}
    >
        Adicionar Material
    </Button>

    let listMaterials = values.materials.map((_, index) => 
        <Fragment key={`material_${index}`}>
            <MaterialTile index={index} />
            {index < values.materials.length - 1 && <Divider />}
        </Fragment>
    )

    return (
        <div hidden={value !== index}>
            {value === index && (
                <MDBox p={3}>
                    {values.materials.length != 0 && listMaterials}
                    {addMaterialButton}
                </MDBox>
            )}
        </div>
    )
}

export default MaterialTab;