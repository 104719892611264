import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import StorageTile from "./components/StorageTile";

import { 
    Card, Table, TableBody, TableRow, TableCell, TablePagination 
} from "@mui/material";

import { getAxios } from "../../utils";

import BarLoader from "react-spinners/BarLoader";
import { createSearchParams, useNavigate } from 'react-router-dom';
import SearchBar from "components/SearchBar";
import TablePaginationActions from "components/TablePaginationActions";

const maxStorages = 10;

function Storages() {
    const [storages, setStorages] = useState([]);
    const [totalStorages, setTotalStorages] = useState(0);
    const [page, setPage] = useState(0);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');

    const navigate = useNavigate();
    
    const handleChangePage = (_, newPage) => setPage(newPage);

    const handleGetStorages = async() => {
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/storage";
        let response;
        const params = { 
            search,
            limitStart: page*maxStorages, 
            limit: maxStorages 
        }

        try {
            response = await axiosInstance.get(uri, { params });
        } catch(err) {
            if(err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }

            setError("Erro interno");
            return;
        }
        
        if(!response.data.result) {
            setError("Erro interno");
            return;
        }

        setError(undefined);
        setLoading(false);
        setStorages(response.data.result.storages);
        setTotalStorages(response.data.result.total)
    }

    useEffect(async() => { 
        await handleGetStorages() 
    }, [page, search]);
    
    const title = <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
    >
        <MDTypography variant="h6" color="white">
            Storages
        </MDTypography>
    </MDBox>

    const handleSearch = async (value) => {
        setSearch(value);
        setPage(0);
    } 

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mt={4}>
                {!!error && <MDAlert color="error">{error}</MDAlert>}
                <Card>
                    {title}
                    <MDBox pt={3}>
                        <SearchBar label="Pesquisar" onSearch={handleSearch} />
                        <Table>
                            <MDBox component="thead">
                                <TableRow>
                                    <TableCell><strong>ID</strong></TableCell>
                                    <TableCell><strong>Nome</strong></TableCell>
                                </TableRow>
                            </MDBox>
                            <TableBody>{storages.map((storage, index) => <StorageTile 
                                    key={`storage_${index}`} 
                                    storage={storage} 
                                />
                            )}</TableBody>
                        </Table>
                        {loading &&
                            <BarLoader
                                color="#ACC0F0" 
                                cssOverride={{ width: "100%" }}
                            />
                        }
                        <TablePagination 
                            rowsPerPageOptions={[maxStorages]}
                            component="div"
                            count={totalStorages}
                            rowsPerPage={maxStorages}
                            page={page}
                            onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    )
}

export default Storages;