import React, { useEffect, useState } from "react";
import * as yup from 'yup';
import moment from "moment";

import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, Typography
} from "@mui/material";

import { getAxios, timestampToSql } from "utils";
import DefaultTextField from "components/DefaultTextField";
import { useNavigate } from "react-router-dom";
import DefaultSelectField from "components/DefaultSelectField";
import DefaultCheckField from "components/DefaultCheckField";
import DefaultDateTimeField from "components/DefaultDateTimePicker";
import { useFormik } from "formik";
import DefaultAutoCompleteField from "components/DefaultAutoCompleteField";

moment.updateLocale('en', { invalidDate: 'Não definido' });

function PlanFormModal({ open, handleClose, plan = null }) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [branches, setBranches] = useState([]);
    const [title, setTitle] = useState(plan != null
        ? plan.name
        : 'Novo Plano'
    );

    const initialValues = {
        id: plan?.id ?? '',
        branch: plan?.branch ?? '',
        isPerPersonUnique: plan?.isPerPersonUnique ?? false,
        maxDevices: plan?.maxDevices ?? '',
        name: plan?.name ?? '',
        currency: plan?.priceDetail?.currency ?? '',
        recurrencePeriod: plan?.priceDetail?.recurrencePeriod ?? '',
        recurrenceUnit: plan?.priceDetail?.recurrenceUnit ?? '',
        recurring: plan?.priceDetail?.recurring ?? '',
        setup: plan?.priceDetail?.recurring ?? '',
        vatRate: plan?.priceDetail?.vatRate ?? '',
        priceType: plan?.priceType ?? '',
        priceWithVat: plan?.priceWithVat ?? '',
        priceWithoutVat: plan?.priceWithoutVat ?? '',
        synopsis: plan?.synopsis ?? '',
        type: plan?.type ?? '',
        validFrom: plan?.validFrom ? timestampToSql(plan?.validFrom) : '',
        validTo: plan?.validTo ? timestampToSql(plan?.validTo) : '',
        vatAmount: plan?.vatAmount ?? '',
        vatRate: plan?.vatRate ?? '',
        visible: plan?.visible ?? false,
        freePeriod: plan?.freePeriod ?? ''
    };

    const saveChanges = async (values) => {
        const formData = new FormData();
        const axiosInstance = getAxios();

        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        try {
            if (plan != null) {
                const uri = '/proxy/admin/options/edit';
                await axiosInstance.post(uri, formData);
            } else {
                const uri = '/proxy/admin/options';
                await axiosInstance.post(uri, formData);
            }
            handleClose();
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            return;
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required(),
        branch: yup.number().required(),
        currency: yup.string().required(),
        recurrencePeriod: yup.number().required(),
        recurrenceUnit: yup.string().required(),
        recurring: yup.string().required(),
        setup: yup.string().required(),
        synopsis: yup.string().required(),
        type: yup.number().required(),
        validFrom: yup.date().required(),
        validTo: yup
            .string()
            .test({
                name: 'valid date',
                test(value, ctx) {
                    const validTo = moment(value);
                    const validFrom = moment(yup.ref('validFrom'));
                    return validTo.isAfter(validFrom)
                        ? true
                        : ctx.createError({ message: 'Data inválida' })
                }
            }).required(),
        visible: yup.bool().required(),
        maxDevices: yup.number().required()
    });

    const {
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
    } = useFormik({
        initialValues,
        onSubmit: saveChanges,
        validationSchema
    });

    useEffect(() => {
        if (plan != null) {
            setTitle(values.name + ' ' + (values.lastName ?? ''));
        }
    }, [plan, values.name, values.lastName])

    const handleGetBranches = async () => {
        setLoading(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/branches";
        let response;

        try {
            response = await axiosInstance.get(uri);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
                return;
            }
            if (err.response.data.error.code == 404) {
                setError("Não há mais pacotes disponíveis")
                return;
            }

            setError("Erro interno");
            return;
        }

        if (!response.data) {
            setError("Erro interno");
            return;
        }

        setError(undefined);

        let branchesAux = response.data.result.branches.map(branch => ({
            label: branch.name,
            id: branch.id
        }));
        setBranches(branchesAux);
        setLoading(false);
    }

    useEffect(() => { if (open) handleGetBranches() }, [open]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {!loading &&
                    <DefaultAutoCompleteField
                        label="Branch"
                        value={values.branch}
                        onChange={newValue => {
                            const value = newValue ? newValue.id : '';
                            setFieldValue('branch', value)
                        }}
                        options={branches}
                        error={touched.branch && Boolean(errors.branch)}
                        helperText={touched.branch && errors.branch}
                    />
                }
                <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                        <DefaultTextField
                            label="Nome"
                            value={values.name}
                            name='name'
                            onChange={handleChange}
                            required
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DefaultSelectField
                            required
                            value={values.type}
                            label='Tipo de plano'
                            name='type'
                            onChange={handleChange}
                            items={window.stxConfig.planTypes}
                            error={touched.type && Boolean(errors.type)}
                            helperText={touched.type && errors.type}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DefaultTextField
                            required
                            label="Máximo de Dispositivos conectados"
                            value={values.maxDevices}
                            name='maxDevices'
                            onChange={handleChange}
                            type='number'
                            error={touched.maxDevices && Boolean(errors.maxDevices)}
                            helperText={touched.maxDevices && errors.maxDevices}
                        />
                    </Grid>
                </Grid>

                <Typography variant="h4" component='h2'>
                    Descrição
                </Typography>

                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <DefaultTextField
                            required
                            label="Descrição"
                            value={values.synopsis}
                            name='synopsis'
                            onChange={handleChange}
                            multiline
                            error={touched.synopsis && Boolean(errors.synopsis)}
                            helperText={touched.synopsis && errors.synopsis}
                        />
                    </Grid>
                </Grid>

                <Typography variant="h4" component='h2'>
                    Preço
                </Typography>

                <Grid container columnSpacing={2}>
                    <Grid item xs>
                        <DefaultSelectField
                            required
                            label="Tipo de Recorrência"
                            value={values.recurrenceUnit}
                            name='recurrenceUnit'
                            onChange={handleChange}
                            items={window.stxConfig.recurrenceTypes}
                            error={touched.recurrenceUnit && Boolean(errors.recurrenceUnit)}
                            helperText={touched.recurrenceUnit && errors.recurrenceUnit}
                        />
                    </Grid>
                    <Grid item xs>
                        <DefaultTextField
                            required
                            label="Período de Recorrência"
                            value={values.recurrencePeriod}
                            name='recurrencePeriod'
                            onChange={handleChange}
                            error={touched.recurrenceUnit && Boolean(errors.recurrenceUnit)}
                            helperText={touched.recurrenceUnit && errors.recurrenceUnit}
                        />
                    </Grid>
                </Grid>

                <Grid container columnSpacing={2}>
                    <Grid item xs>
                        <DefaultSelectField
                            required
                            label="Moeda"
                            value={values.currency}
                            name='currency'
                            onChange={handleChange}
                            items={window.stxConfig.currencies}
                            error={touched.currency && Boolean(errors.currency)}
                            helperText={touched.currency && errors.currency}
                        />
                    </Grid>
                    <Grid item xs>
                        <DefaultTextField
                            required
                            label="Valor setup"
                            value={values.setup}
                            name='setup'
                            onChange={handleChange}
                            error={touched.setup && Boolean(errors.setup)}
                            helperText={touched.setup && errors.setup}
                        />
                    </Grid>
                    <Grid item xs>
                        <DefaultTextField
                            required
                            label="Valor recorrente"
                            value={values.recurring}
                            name='recurring'
                            onChange={handleChange}
                            error={touched.recurring && Boolean(errors.recurring)}
                            helperText={touched.recurring && errors.recurring}
                        />
                    </Grid>
                </Grid>

                <DefaultTextField
                    required
                    label="Período Gratuito (em dias)"
                    value={values.freePeriod}
                    name='freePeriod'
                    onChange={handleChange}
                    error={touched.freePeriod && Boolean(errors.freePeriod)}
                    helperText={touched.freePeriod && errors.freePeriod}
                />

                <Typography variant="h4" component='h2'>
                    Validade
                </Typography>

                <Grid container justifyContent='space-between'>
                    <DefaultDateTimeField
                        required
                        value={values.validFrom}
                        label="A partir de"
                        onChange={val => setFieldValue('validFrom', val)}
                        error={touched.validFrom && Boolean(errors.validFrom)}
                        helperText={touched.validFrom && errors.validFrom}
                    />

                    <DefaultDateTimeField
                        required
                        value={values.validTo}
                        label="Até"
                        onChange={val => setFieldValue('validTo', val)}
                        error={touched.validTo && Boolean(errors.validTo)}
                        helperText={touched.validTo && errors.validTo}
                    />

                </Grid>

                <DefaultCheckField
                    label="Plano visível?"
                    name="visible"
                    checked={values.visible}
                    onChange={handleChange}
                />
                <DefaultCheckField
                    label="É único por pessoa?"
                    name="isPerPersonUnique"
                    checked={values.isPerPersonUnique}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PlanFormModal;
