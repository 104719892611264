import {
    Box, Button, CircularProgress, Divider, Icon, ListItem, ListItemText, Typography
} from "@mui/material";
import MDBox from "components/MDBox";
import StorageField from "components/StorageField";
import { useFormikContext } from "formik";
import { Fragment, useEffect, useState } from "react";
import { getAxios } from "utils";

function MediaTab({ value, index }) {
    const [medias, setMedias] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [changeMedia, setChangeMedia] = useState(false);

    const {
        values,
        setFieldValue,
    } = useFormikContext();

    const handleGetMedias = async () => {
        setLoading(true);
        const axiosInstance = getAxios();

        const uri = "/proxy/admin/assets/medias";
        let response;
        const params = { id: values.id }

        try {
            response = await axiosInstance.get(uri, { params });
            setError(undefined);

            setMedias(response.data.result.medias);
        } catch (err) {
            if (err.response.data.error.message === "Invalid Auth") {
                navigate({
                    pathname: '/authentication/sign-in',
                    search: createSearchParams({
                        session: "expired"
                    }).toString()
                });
            } else if (err.response.data.error.code == 404) {
                setError("Não há medias disponíveis")
            } else {
                setError("Erro interno");
            }
        }

        setLoading(false);
    }

    useEffect(() => {
        if (values.id != null) handleGetMedias();
    }, [values.id])

    const handleChangeMedia = () => setChangeMedia(true);

    let listMedias = <>
        {medias.map((media, index) => <Fragment key={`media_${index}`}>
            <ListItem >
                <ListItemText
                    primary={media.url}
                    secondary={
                        <Fragment>
                            <Typography>Tecnologia: {media.technology}</Typography>
                            <Typography>Status: {media.status}</Typography>
                        </Fragment>
                    }
                />
            </ListItem>
            {index < medias.length - 1 && <Divider />}
        </Fragment>)}
        <Box
            display='flex'
            justifyContent='flex-end'
        >
            <Button
                variant="contained"
                style={{ color: 'white' }}
                onClick={handleChangeMedia}
                startIcon={<Icon>swap_horiz</Icon>}
            >
                Trocar Media
            </Button>
        </Box>
    </>

    let addMediaField = <StorageField
        label='Media'
        value={values.media}
        onSelect={(path) => setFieldValue('media', path)}
    />

    let addMedia = values.id == null || changeMedia || medias.length === 0;

    return (
        <div hidden={value !== index}>
            {value === index && (
                <MDBox p={3}>
                    {addMedia
                        ? addMediaField
                        : loading
                            ? <CircularProgress />
                            : listMedias
                    }
                </MDBox>
            )}
        </div>
    )
}

export default MediaTab;